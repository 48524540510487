import { Breadcrumb, Button, Card, Flex, Form, Input, InputNumber, Row, Select, notification, Col } from "antd";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm } from "antd/es/form/Form";
import emailjs from 'emailjs-com';
import { Atom } from "react-loading-indicators";
import { useInstitucion } from "../../Hooks/Institucion/useInstitucion";

const ReporteGuardar = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    
    const [cargando, setCargando] = useState(false);
    const [form] = useForm();
    const {instituciones,getAllInstitucion} = useInstitucion()

    const miga = [
        { title: <Link to="/">Inicio</Link> },
        { title: <Link to="/reportes">Registrar reporte</Link> }
    ];

    const sendEmail = async (e) => {
        setCargando(true);
        try {
            const values = form.getFieldsValue();

            values.ins = instituciones.find(i => i._id === values.ins).nombre;
            await emailjs.send('service_ex36dms', 'template_p25b31z', values, 'ViEbaQjgr9dRHdKP_');
            notification.success({ message: 'Correo enviado exitosamente' });
            form.resetFields();
        } catch (error) {
            notification.error({ message: 'Error al enviar el correo', description: error.text });
        }
        finally {
            setCargando(false);
        }
    };

    

    const cargarIns = async () => {
        try {
            setCargando(true);
            getAllInstitucion()
        } catch (error) {
            notification.error({ description: error.response?.data?.mensaje || 'Error al cargar instituciones' });
            navigate('/atletas');
        }
        finally {
            setCargando(false);
        }
    };

    useEffect(() => {
        cargarIns();
        if (id) {
           
        }
    }, [id]);

    return (
        <div style={{ padding: 32 }}>
            <Breadcrumb items={miga} />
            <br />
            {cargando ? <Flex align="center" justify="center">
                <Atom color="#32cd32" size="medium" text="" textColor="" />
            </Flex> : 
            <Flex align="center" justify="center">
                <Card style={{ width: '90%', maxWidth: 960 }}>
                    <Form form={form} layout="vertical" onFinish={sendEmail}>
                        <Row gutter={[16, 16]} align="center" justify="center">
                            <Col xs={24} md={16}>
                                <Form.Item name="name" label="Nombre" rules={[{ required: true, message: 'Campo requerido' }]}>
                                    <Input style={{ width: '100%' }} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[16, 16]} align="center" justify="center">
                            <Col xs={24} md={16}>
                                <Form.Item name="ins" label="Institución">
                                    <Select
                                        style={{ width: '100%' }}
                                        options={instituciones.map(i => ({ value: i._id, label: i.nombre }))}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[16, 16]} align="center" justify="center">
                            <Col xs={24} md={16}>
                                <Form.Item name="correo" label="Correo electrónico" rules={[{ required: true, message: 'Campo requerido' }]}>
                                    <Input style={{ width: '100%' }} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[16, 16]} align="center" justify="center">
                            <Col xs={24} md={16}>
                                <Form.Item name="tel" label="Teléfono" rules={[{ required: true, message: 'Campo requerido' }]}>
                                    <InputNumber style={{ width: '100%' }} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[16, 16]} align="center" justify="center">
                            <Col xs={24} md={16}>
                                <Form.Item name="message" label="Descripción" rules={[{ required: true, message: 'Campo requerido' }]}>
                                    <Input.TextArea autoSize={{ minRows: 2, maxRows: 6 }} style={{ width: '100%' }} showCount size="large" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <br />
                        <Flex justify="center">
                            <Button loading={cargando} type="primary" htmlType="submit">Enviar</Button>
                        </Flex>
                        
                    </Form>
                </Card>
            </Flex>}
        </div>
    );
};

export default ReporteGuardar;
