
import useSesion from "../Hooks/Sesion"
import { useState } from "react"
import API from "../API"
import { notification } from "antd"


export default function(){
    const {login} = useSesion()
    const [cargando,setCargando] = useState(false)

    const acceder = async (payload)=>{
        try{
            setCargando(true)
            const {data} = await API.post('usuario/login',payload)
            login({
                token:data.token,
                redirigir:true
            })
        }catch(err){
           
            setCargando(true)
            notification.error({description:err.response.data.mensaje || err.toString()})
        }finally{
            setCargando(false)
        }
    }
    return({
       
        acceder,
        cargando
    })
}