import { Breadcrumb, Button, Card, Flex, Form, Input, Popconfirm, Select, Switch, Table, message, notification } from "antd"
import { useEffect, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import API from "../../../API"
import { DeleteOutlined, EditOutlined } from "@ant-design/icons"
import { useForm } from "antd/es/form/Form"
import ValidarRol from "../../../Componentes/ValidarRol"
import AccesoProhibido from "../../../Componentes/AccesoProhibido"

const UsuarioGuardar = () => {
    const [doc, setDoc] = useState({})
    const [instituciones, setInstituciones] = useState([])
    const navigate = useNavigate()
    const { id } = useParams()
    const [cargando, setCagando] = useState(false)
    const [form] = useForm()
    const [form2] = useForm()
    const [form3] = useForm()

    const miga = [
        {
            title: <Link to="/">Inicio</Link>
        },
        {
            title: <Link to="/usuarios">Usuarios</Link>
        },
        {
            title: 'Guardar'
        }
    ]

    const cargar = async () => {
        await loadInstituciones()
        if (!id) {
            return
        }

        try {
            setCagando(true)
            const { data } = await API(`usuario/${id}`)
            //console.log(data.doc)
            setDoc(data.doc)
            //console.log(data.doc)
        } catch (err) {
            message.error({
                message: err.toString(),
                content: 'Usuario no cargado'
            })
            navigate('/usuarios')
        } finally {
            setCagando(false)
        }
    }
    const loadInstituciones = async () => {
        try {
            const { data } = await API('institucion')
            //console.log(data.docs)
            setInstituciones(data.docs)
        } catch (err) {
            notification.error({
                message: 'Error al cargar instituciones',
                description: err.toString()
            })
        }
    }
    const guardar = async (payload)=>{
        
        try{
            setCagando(true)
            const url = id ? `usuario/${id}`:'usuario'
            const method = id ? 'patch':'post'

            await API({
                url,
                method,
                data:payload
            })
            message.success({
                content:'Usuario guardado'
            })
            if(!id){
                navigate('/usuarios')
            }
        }catch(err){
            notification.error({
                description:err?.response?.data?.mensaje || err.toString(),
                message:'Usuario no guardado'
            })
        }finally{
            setCagando(false)
        }
    }

    const cambiarClave=async (payload)=> {
        try{
            setCagando(true)
            //console.log(form2.getFieldsValue())
            await API.patch(`usuario/${id}`,payload)
            notification.success({
                message:'Clave actualizada'
            })
            form2.setFieldValue('clave','')
        }catch(err){
            notification.error({
                description:err?.response?.data?.mensaje || err.toString(),
                message:'Clave no actualizada'
            })
        }finally{
            setCagando(false)
        }
    }

    const changeInstitucion=async (payload)=> {
        try{
            setCagando(true)
            //console.log(form3.getFieldsValue())
            await API.patch(`usuario/${id}`,payload)
            notification.success({
                message:'institucion actualizada'
            })
            form3.setFieldValue('institucion','')
        }catch(err){
            notification.error({
                description:err?.response?.data?.mensaje || err.toString(),
                message:'Institucion no actualizada'
            })
        }finally{
            setCagando(false)
        }
    }

    useEffect(() => {
        cargar()
        
    }, [id])

    useEffect(() => {
        
        if (doc?._id) {
            form.setFieldsValue(doc)
        }
    }, [doc])

    return <ValidarRol roles={['Admin']} FeedBack={AccesoProhibido}><div style={{ padding: 32 }}>
        <Flex>
            <Breadcrumb items={miga} />
        </Flex>
        <br></br>
        <br></br>
        <div style={{ maxWidth: 800, margin: '0 auto' }}>
            <Card>
                <Form form={form} layout="vertical" onFinish={guardar}>
                    <Form.Item label="usuario" name="usuario" rules={[{ required: true, message: 'Campo requerido' }]}>
                        <Input />
                    </Form.Item>
                    {!doc?._id ? <Form.Item label="Clave" name="clave" rules={[{ required: true, message: 'Campo requerido' }]}>
                        <Input.Password />
                    </Form.Item> : null}

                    <Form.Item label="Rol" name="rol" rules={[{ required: true, message: 'Campo requerido' }]}>
                        <Select options={[{value:'Admin',label:'Administrador'},{value:'Regular',label:'Usuario regular'}]} />
                    </Form.Item>

                    {!doc?._id ? <Form.Item 
                                label="Institucion" 
                                name="institucion" 
                                rules={[{ required: true, message: 'Campo requerido' }]}
                            >
                                <Select 
                                    options={instituciones.map(i => ({ value: i._id, label: i.nombre }))}
                                    defaultValue={doc?.institucion}
                                />
                    </Form.Item> : null}

                    <Form.Item label="Activo" name="activo" valuePropName="checked" >
                        <Switch  />
                    </Form.Item>

                    <Form.Item label="nombre" name="nombre" rules={[{ required: true, message: 'Campo requerido' }]}>
                        <Input />
                    </Form.Item>
                    <Button type="primary" htmlType="submit">Guardar</Button>
                </Form>
            </Card>
            <br></br>
            <br></br>
            
            {doc?._id ? (
                <>
                    <Card>
                        <Form layout="vertical" onFinish={(values) => changeInstitucion(values)} form={form3}>
                            <Form.Item 
                                label="Nueva Institucion" 
                                name="institucion" 
                                rules={[{ required: true, message: 'Campo requerido' }]}
                            >
                                <Select 
                                    options={instituciones.map(i => ({ value: i._id, label: i.nombre }))}
                                    defaultValue={doc?.institucion}
                                />
                            </Form.Item>
                            <Button type="primary" htmlType="submit">Actualizar Institucion</Button>
                        </Form>
                    </Card>
                </>
            ) : null}
            <br></br>
            {doc?._id ? (
                <>
                    <Card>
                        <Form layout="vertical" onFinish={(values) => cambiarClave(values)} form={form2}>
                            <Form.Item 
                                label="Nueva clave" 
                                name="clave" 
                                rules={[{ required: true, message: 'Campo requerido' }]}
                            >
                                <Input.Password />
                            </Form.Item>
                            <Button type="primary" htmlType="submit">Actualizar clave</Button>
                        </Form>
                    </Card>
                </>
            ) : null}
           


        </div>
    </div>
    </ValidarRol>
}

export default UsuarioGuardar