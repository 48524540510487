import { useState } from "react"
import  API  from "../../API"
import useSesion from "../Sesion"
import dayjs from "dayjs";
export function useAtleta() {
    const {usuario} = useSesion()
    const [atletas, setAtletas] = useState([])
    const [lesiones, setLesiones] = useState({})

    const getAtleta = async (id) => {
            const { data } = await API(`atleta/${id}`)
            setAtletas([data?.doc])
            const lesion = await data?.doc?.lesiones?.map(l=>({...l,fecha:dayjs(l.fecha)}))
            setLesiones(lesion)
         
    }
    const getAtletas = async (param) =>{
            const params = {
                institucion: usuario?.rol === 'Admin' ? param.institucion : usuario?.institucion
            };
            console.log(params)
            const { data } = await API('atleta',{params})
            setAtletas(data.docs)
    }
    const deleteAtleta=async (id)=>{
        try{
        
            const url = `atleta/${id}`
            const method = "delete"
            console.log(url)
            await API({
                url,
                method
            })
        }catch(err){
            console.log(err)
        }
    }
    const saveAtleta = async (data) => {
        try {
  
            const url = 'atleta'
            const method = "post"
            await API({
                url,
                method,
                data
            })
        } catch (err) {
            console.log(err)
        }
    }
    const updateAtleta = async (id, data) => {
        try {
            
            const url = `atleta/${id}`
            const method = "patch"
            await API({
                url,
                method,
                data
            })
        } catch (err) {
            console.log("No actualice")
        } 
    }

    return {
      atletas,
      lesiones,
      getAtletas,
      deleteAtleta,
      saveAtleta,
      updateAtleta,
      getAtleta
      
    }

}