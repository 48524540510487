import { useEffect, useState } from 'react';
import { createContext } from "react";
import { useParams } from 'react-router-dom';

import { useAtleta } from '../../Hooks/Atleta/useAtleta'
import { usePrueba } from '../../Hooks/Prueba/usePrueba';

import { notification } from 'antd';
import { useNavigate } from 'react-router-dom';

export const PruebaContext = createContext();

export const PruebaContextProvider = ({ children }) => {
    const { id } = useParams()
    const navigate = useNavigate()
    const {pruebas, getPrueba} = usePrueba()
    const {atletas, getAtleta} = useAtleta()
    const [cargando, setCargando] = useState(false)
 
    

    const cargar = async () => {
            try {
                setCargando(true)
                await getPrueba(id)
            } catch (err) {
                notification.error({ description: err?.response?.data?.mensaje || err.toString() })
                navigate('/pruebas')
            } finally {
                setCargando(false)
            }
        }
    
    const cargarAtleta = async () => {
            try {
                setCargando(true)
                await getAtleta(pruebas[0]?.atleta)
            } catch (error) {
                notification.error({ description: error.response.data.mensaje })
                navigate('/pruebas')
            } finally {
                setCargando(false)
            }
        }
    
    
        useEffect(() => {
            cargar()
        }, [id])
    
        useEffect(() => {
            if (pruebas[0]?.atleta) {
                cargarAtleta()
            }
        }, [pruebas])

    return <PruebaContext.Provider value={{ pruebas, 
        atletas, 
        cargando, 
        setCargando, 
        id}}>
        {children}
    </PruebaContext.Provider>
}