import { useInstitucion } from "../../Hooks/Institucion/useInstitucion"
import { useEffect } from "react"
import { Form, Input, Select, DatePicker, Flex, Button, notification } from "antd"
import { FilterOutlined } from "@ant-design/icons"
import useSesion from "../../Hooks/Sesion"

export const FiltrosPruebas = ({cargar, form, cargando, setCargando}) =>{
    const {instituciones, getAllInstitucion, getInstitucion} = useInstitucion()
    const {usuario} = useSesion()

    const cargarIns = async () => {
        setCargando(true)
        try {
            usuario?.rol === 'Admin' ? await getAllInstitucion() : await getInstitucion(usuario?.institucion)
        } catch (error) {
            notification.error({ description: error.response.data.mensaje })
            
        }
        finally {
            setCargando(false);
        }
    }

    useEffect(() => {
        cargarIns()
    }, [])

    return (
        <>
            <br></br>
            <Form form={form} layout="vertical" onFinish={cargar}>

                <Flex gap="middle">
                    <Form.Item name="fecha" label="Rango de fecha" rules={[{ required: false, message: 'Ingrese un rango de fecha' }]}>
                        <DatePicker.RangePicker />
                    </Form.Item>
                    <Form.Item name="tipoPrueba" label="Tipo de Prueba" >
                        <Select allowClear style={{width:200}} options={[{ label: 'CMJ', value: 'CMJ' },  { label: 'Nordic', value: 'Nordic' }]} />
                    </Form.Item>
                    <Form.Item name="momento" label="Momento" >
                        <Select allowClear style={{width:200}} options={[{ label: 'Único', value: 'Único' }, { label: 'Pre', value: 'Pre' }, { label: 'Post', value: 'Post' }, { label: '24H', value: '24H' }, { label: '48H', value: '48H' }, { label: '72H', value: '72H' }, { label: '96H', value: '96H' }]} />
                    </Form.Item>
                    <Form.Item name={'atleta'} label="Atleta" >
                        <Input allowClear style={{width:'100%'}} />
                    </Form.Item>
                    <Form.Item name={'institucion'} label="Institución" >
                        <Select style={{width:200}}  
                        options={instituciones.map(i => ({ value: i._id, label: i.nombre }))} 
                        defaultValue={usuario?.institucion}
                        allowClear={usuario?.rol === 'Admin'}
                        />
                    </Form.Item>
                </Flex>
                <Flex width="100%" gap="" justify="space-between"> 
                    <Button loading={cargando} type="primary" htmlType="submit" icon={<FilterOutlined />}>Filtrar</Button>
                </Flex>
                
            </Form>
            <br></br>
        </>
    )
}
