import { notification, Breadcrumb, Flex, Typography, Table, Button,Form,Select} from "antd"

import { useEffect, useRef, useState } from "react"
import { useNavigate, useParams, Link, useLocation } from "react-router-dom"
import ECharts from 'echarts-for-react';
import ecStat from 'echarts-stat';
import * as echarts from 'echarts';
import API from "../../../API"

import { runCMJ } from "../CMJ/Guardar/cmj";
import { runNordic } from "../Nordic/Guardar/nordic";
//import VideoFases from "../../../Componentes/VideoFases";

//import calcularParametros from "../CMJ/Guardar/Rep";

import CrearGrupoModalCuadrante from "./ModalCuadrante";
import CrearGrupoModalCuadranteParams from "./modalCuadranteParams";

import { optionsParametrosCmj, optionsParametrosNordic } from "./Enum/OptionsParametros";
import { TableCuadrantes } from "../../../Componentes/Pruebas/TableCuadrantes";



const PruebaCuadrante = () => {
 
    const navigate = useNavigate()
    const { id } = useParams()
    const query = new URLSearchParams(useLocation().search); // Extrae los Query Params
    const extraParam = query.get('extraParam');
    const [doc, setDoc] = useState([])
    var [parametrosT, setParametrosT] = useState(["parametro1","parametro2"])
    const [cargando, setCargando] = useState(false)
    const grafica = useRef(null)
    
 
    const [newparametros, setParametros] = useState([])
    const [name, setName] = useState("")
    const [recalculando, setreCalculando] = useState(false)
    const [paramsAutomatic, setParamsAutomatic] = useState([true,true])
    const [table, setTable] = useState([[[0,0]]])
    const [abrir,setAbrir] = useState(false);
    const [abrirParams,setAbrirParams] = useState([false,0]);
    const [limitesEjes,setLimitesEjes] = useState([-1500,1500,-1500,1500])
    const [optionsParametros, setOptionsParmetros] = useState([]); 
    const [centersDate, setCentersDate] = useState([0, 0]);

    const getData = async (element) => {
        const nombre = extraParam==="prueba" ? "atleta": "Muestra"
        setName(nombre)
        const endpoint = extraParam === "prueba" ? `prueba/${element}` : `muestra/${element}`;
        const data = await API(endpoint);
        return data;
    };
    
    const cargar = async () => {
        try {
            setCargando(true);
    
            const elements = id.split(",");
            const updatedDocs = await Promise.all(
                elements.map(async (element, index) => {
                    const { data } = await getData(element);
                    if(data?.doc.tipoPrueba === "CMJ"){
                        setOptionsParmetros(optionsParametrosCmj); // Usa setState
                    }
                    else{
                        setOptionsParmetros(optionsParametrosNordic);
                    }
                    
    
                    if (extraParam === "prueba") {
                        const parametros = await calcCuadrante(
                            data?.doc.sistema_de_pesos_y_tiempos,
                            index,
                            data?.doc.tipoPrueba
                        );
                        return {
                            ...data.doc,
                            id: index,
                            parametros,
                        };
                    } else {
                        //console.log(data?.doc)
                        return {
                            ...data?.doc,
                            id: index,
                        };
                    }
                })
            );
    
            setDoc(updatedDocs);
        } catch (err) {
            notification.error({ description: err?.response?.data?.mensaje || err.toString() });
            navigate('/pruebas');
        } finally {
            setCargando(false);
        }
    };
    
    const calcCuadrante = async (subset1, tipoPrueba) => {
        setreCalculando(true);

        if (tipoPrueba === "CMJ") {
            
            const { parametros: parametros_calculados } = await runCMJ(subset1);
            setParametros([...parametros_calculados]);
           
            setreCalculando(false);
            return parametros_calculados;
        } else {
            
            const { parametros: parametros_calculados } = await runNordic(subset1);
            setParametros([...parametros_calculados]);
          
            setreCalculando(false);
            return parametros_calculados;
        }
    };

    const miga = [
        {
            title: <Link to="/">Inicio</Link>
        },
        {
            title: <Link to="/pruebas">Pruebas realizadas</Link>
        },
        {
            title: <Link to="/cuadrante">Cuadrante</Link>
        },
        
    ]
    
    echarts.registerTransform(ecStat.transform.clustering);
   
    var CLUSTER_COUNT = 1;
    var DIENSIION_CLUSTER_INDEX = 2;
    var COLOR_ALL = [
    '#37A2DA',
    '#e06343',
    '#37a354',
    '#b55dba',
    '#b5bd48',
    '#8378EA',
    '#96BFFF'
    ];
    var pieces = [];
    for (var i = 0; i < CLUSTER_COUNT; i++) {
    pieces.push({
        value: i,
        label: 'cluster ' + i,
        color: COLOR_ALL[i]
    });
    }

    var opciones_grafica = {
        dataset: [
            {
                source: table
            },
            {
                transform: {
                    type: 'ecStat:clustering',
                    config: {
                        clusterCount: CLUSTER_COUNT,
                        outputType: 'single',
                        outputClusterIndexDimension: DIENSIION_CLUSTER_INDEX
                    }
                }
            }
        ],
        tooltip: {
            position: 'top',
            formatter: function (params) {
                // Mostrar información solo para puntos de datos (no líneas ni etiquetas)
                if (params.componentType === 'series') {
                    const index = params.dataIndex;
                    const usuario = doc[index];
                    //console.log(usuario)
                    return `
                        Nombre: ${usuario.nombre}<br>
                        Momento: ${usuario.momento}<br>
                        Sexo: ${usuario.sexo}<br>
                        Cuadrante: ${usuario.cuadrante}<br>
                        X: ${params.value[0]}<br>
                        Y: ${params.value[1]}
                    `;
                }
                return ''; 
            }
        },
        grid: {
            left: 120
        },
        xAxis: {
            type: 'value',
            name: parametrosT[0],
            nameLocation: 'middle',
            axisLabel: {
                formatter: '{value}',
                align: 'center',
                textStyle: { color: () => 'whitesmoke' },
            },
            nameGap: 50,
            data: table[1],
            min: limitesEjes[0],
            max: limitesEjes[1],
        },
        yAxis: {
            type: 'value',
            name: parametrosT[1],
            nameLocation: 'middle',
            nameGap: 50,
            axisLabel: {
                textStyle: { color: () => 'whitesmoke' },
            },
            min: limitesEjes[2],
            max: limitesEjes[3],
            data: table[0],
        },
        series: {
            type: 'scatter',
            encode: { tooltip: [0, 1] },
            symbolSize: 15,
            itemStyle: {
                borderColor: '#555'
            },
            data: table[0],
            markLine: {
                silent: true, // Desactiva el tooltip para las líneas
                symbol: 'none',
                lineStyle: {
                    type: 'dotted',
                    color: 'orange'
                },
                data: [
                    { xAxis: centersDate[0] }, // Línea vertical en el centro X
                    { yAxis: centersDate[1] }  // Línea horizontal en el centro Y
                ]
            },
            markPoint: {
                silent: true, // Desactiva interacciones para las etiquetas
                symbol: 'circle',
                symbolSize: 1, // Ocultar símbolo visual
                label: {
                    show: true,
                    fontSize: 18,
                    fontWeight: 'bold',
                    color: 'rgba(0, 0, 0, 0.5)', // Color opaco
                    position: 'inside',
                },
                data: [
                    { coord: [centersDate[0] + (limitesEjes[1] - centersDate[0]) / 2, centersDate[1] + (limitesEjes[3] - centersDate[1]) / 2], value: 'I' },
                    { coord: [centersDate[0] - (centersDate[0] - limitesEjes[0]) / 2, centersDate[1] + (limitesEjes[3] - centersDate[1]) / 2], value: 'II' },
                    { coord: [centersDate[0] - (centersDate[0] - limitesEjes[0]) / 2, centersDate[1] - (centersDate[1] - limitesEjes[2]) / 2], value: 'III' },
                    { coord: [centersDate[0] + (limitesEjes[1] - centersDate[0]) / 2, centersDate[1] - (centersDate[1] - limitesEjes[2]) / 2], value: 'IV' }
                ]
            }
        }
    };
    

      const guardar = async (payload)=>{
        try{
            setCargando(true)
            //console.log(payload)
            const cuadranteData = []
            doc.forEach((element, index) => {
                cuadranteData.push({
                    "id":index,
                    "atleta":element.atleta,
                    "param1":[parametrosT[0],element.param1],
                    "param2":[parametrosT[1],element.param2],
                    "cuadrante":element.cuadrante
                })
            });
            await API.post('cuadrante',{...payload,cuadranteData})
            notification.success({
                description:'Cuadrante creado correctamente, puedes visualizarlo en la sección Cuadrantes'
            })
            
        }catch(err){
            notification.error({
                description:err?.response?.data?.mensaje || err.toString()
            })
            
        }finally{
            setCargando(false)
        }
    }


    const updateData = async () => {
        try {
            setCargando(true);
    
            const newTable = [[]];
            const limites = [0, 0, 0, 0];
            const params = [];
            
            console.log(doc)
   
            doc.forEach((val) => {
                params.push([val.param1, val.param2]);
                newTable[0].push([val.param1, val.param2]);
                
                const porcentajeParam1 = (5 * val.param1) / 100;
                const porcentajeParam2 = (5 * val.param2) / 100;
    
                if (val.param1 < limites[0] || limites[0] === 0) {
                    limites[0] = val.param1 - porcentajeParam1;
                }
                if (val.param1 > limites[1] || limites[1] === 0) {
                    limites[1] = val.param1 + porcentajeParam1;
                }
                if (val.param2 < limites[2] || limites[2] === 0) {
                    limites[2] = val.param2 - porcentajeParam2;
                }
                if (val.param2 > limites[3] || limites[3] === 0) {
                    limites[3] = val.param2 + porcentajeParam2;
                }
            });
    

            let updatedLimites = [...limites];
 
            if (paramsAutomatic[0] && paramsAutomatic[1]) {
 
                updatedLimites = limites;
            
            }else if(!paramsAutomatic[0] && !paramsAutomatic[1]){
                updatedLimites = [limitesEjes[0], limitesEjes[1], limitesEjes[2], limitesEjes[3]];
            } 
            else if (paramsAutomatic[0]) {
        
                updatedLimites = [limites[0], limites[1], limitesEjes[2], limitesEjes[3]];
            } else if (paramsAutomatic[1]) {
       
                updatedLimites = [limitesEjes[0], limitesEjes[1], limites[2], limites[3]];
            }

            const centers = [
                parseFloat(((updatedLimites[0] + updatedLimites[1]) / 2).toFixed(2)),
                parseFloat(((updatedLimites[2] + updatedLimites[3]) / 2).toFixed(2)),
            ];
            setCentersDate(centers);
       
            newTable[0].forEach((value, index) => {
                if (value[0] > centers[0] && value[1] > centers[1]) {
                    Object.assign(doc[index], { cuadrante: "I" });
                }
                if (value[0] < centers[0] && value[1] > centers[1]) {
                    Object.assign(doc[index], { cuadrante: "II" });
                }
                if (value[0] < centers[0] && value[1] < centers[1]) {
                    Object.assign(doc[index], { cuadrante: "III" });
                }
                if (value[0] > centers[0] && value[1] < centers[1]) {
                    Object.assign(doc[index], { cuadrante: "IV" });
                }
            });
    
            
            setLimitesEjes(updatedLimites);
            setTable(newTable);
    
        } catch (error) {
            console.log(error);
        } finally {
            setCargando(false);
        }
    };
    
      
      const handleChangep1 = async (value) => {
        let obje = optionsParametros[value].nombre
        setParametrosT([obje,parametrosT[1]])
 
        
        try {
            setCargando(true)
           
            for(let i=0 ;i<doc.length;i++){
                doc[i].parametros.forEach((val) => {
                    if(val.nombre == obje){
                        Object.assign(doc[i], { "param1": val.valor});
                        
                    }
                })

            }
            
        } catch (error) {
            console.log(error)
        }
        finally{
            setCargando(false)
            //console.log(doc)
        }
        
      };
      const handleChangep2 = async (value) => {
        let obje = optionsParametros[value].nombre
        
        setParametrosT([parametrosT[0],obje])
        
        try {
            setCargando(true)
            let params
            for(let i=0 ;i<doc.length;i++){
                doc[i].parametros.forEach((val) => {
                    if(val.nombre == obje){
                        Object.assign(doc[i], { "param2": val.valor});
                      
                    }
                })

            }
            
        } catch (error) {
            console.log(error)
        }
        finally{
            setCargando(false)
            //console.log(doc)
        }
  
      };

    useEffect(() => {
        cargar()

    }, [])

    



    return <div >
        <div style={{ padding: 32 }}>
            <Breadcrumb items={miga} />
        </div>
        <Typography.Title style={{ textAlign: 'center' }}>{"Informe De Cuadrantes" || ''} </Typography.Title>
         <br></br>
         <Form>

            <Flex gap="middle" justify="space-evenly">
            <Form.Item name={'Parametro1'} label="Parametro1">
                <Select 
                    allowClear 
                    style={{ width: 450, margin: 10 }} 
                    options={optionsParametros} 
                    onChange={handleChangep1}
                />
            </Form.Item>
            <Form.Item name={'parametro2'} label="Parametro2">
                <Select 
                    allowClear 
                    style={{ width: 500 }} 
                    options={optionsParametros} 
                    onChange={handleChangep2}
                />
            </Form.Item>
            </Flex> 


         </Form>
           
        <div style={{ padding: 32 }}>
            <div style={{ height: 600 }}>
                <ECharts ref={grafica} lazyUpdate={true} theme={'dark2'} option={opciones_grafica} style={{ width: '100%', height: '100%' }} />
            </div>
            <br></br>

            <Flex gap="middle" justify="space-around">
                {<Typography.Text>       </Typography.Text>}
                {<Typography.Text>       </Typography.Text>}
                {<Button type="primary"  onClick={updateData}>Calcular Cuadrante</Button>}
             
                {<Typography.Text>       </Typography.Text>}
                {<Typography.Text>       </Typography.Text>}

            </Flex>
            
            
            <br></br>
            <Flex gap="middle" justify="space-evenly">
                <TableCuadrantes cargando={cargando} doc={doc} name={name} parametrosT={parametrosT}/>
            </Flex>
            <br></br>
            <Flex gap="middle" justify="space-evenly">
                <CrearGrupoModalCuadrante abrir={abrir} cerrar={setAbrir} save={guardar}/>
                <CrearGrupoModalCuadranteParams 
                limitesEjes={limitesEjes} changeLimit={setLimitesEjes} 
                abrir={abrirParams[0]} cerrar={setAbrirParams} 
                params={abrirParams[1]} nameParam={parametrosT[(abrirParams[1]-1)]} 
                automatic={paramsAutomatic} 
                setAutomatic={setParamsAutomatic} 
                 />
                  
                {<Button type="primary"  onClick={()=>{setAbrirParams([true,1])}} >Parametro1</Button>}
                {<Button type="primary"  onClick={()=>{setAbrirParams([true,2])}} >Parametro2</Button>}
                </Flex>
            <br></br>
            <br></br>
            <Flex gap="middle" justify="space-around">
                {<Typography.Text>       </Typography.Text>}
                {<Typography.Text>       </Typography.Text>}
                {<Button type="primary"  onClick={()=>{setAbrir(true)}}>Guardar Reporte</Button>}
             
                {<Typography.Text>       </Typography.Text>}
                {<Typography.Text>       </Typography.Text>}

            </Flex>



        </div>
    </div>
}
export default PruebaCuadrante