import { Button, Typography, Flex, notification, Card, theme} from "antd"
import { FilePdfFilled, DatabaseOutlined} from "@ant-design/icons";
import { useRef, useState } from "react"
import * as echarts from 'echarts';
import ECharts from 'echarts-for-react';
import API from "../../API"
import {ThreeDot} from "react-loading-indicators";
import { runCMJ } from "../../Paginas/Prueba/CMJ/Guardar/cmj";
import { runNordic } from "../../Paginas/Prueba/Nordic/Guardar/nordic";
import dayjs from "dayjs";
import SeleccionarMuestra from "../SeleccionarMuestra";
import { usePruebaContext } from "../../Hooks/Prueba/usepruebacontext";
import { TableParamsPrueba } from "./TableParamsPrueba";




export const GraficaPrueba = ({}) =>{
    const grafica = useRef(null)
  
    const [color_area, setColorArea] = useState('whitesmoke')
    const [recalculando, setreCalculando] = useState(false)
    const [newfases, setFases] = useState([])
    const [datosGraficar, setDatosGraficar] = useState([])
    const [inicioFases, setInicioFases] = useState(0)
    const [finFases, setFinFases] = useState(0)
    const [newparametros, setParametros] = useState([])
    const [muestra_seleccionada, setMuestraSeleccionada] = useState(null)
    const {pruebas , atletas, cargando, setCargando, id} = usePruebaContext()
    
    const opciones_grafica = {
        type: 'line',
        legend: {

        },
        tooltip: {
            trigger: 'none',
            axisPointer: {
                type: 'cross'
            }
        },
        xAxis: {
            type: 'category',
            name: 'Tiempo (s)',
            nameLocation: 'middle',
            axisLabel: {
                formatter: '{value}',
                align: 'center',
                textStyle: { color: () => color_area },
            },
            nameGap: 40,
            data: pruebas[0]?.sistema_de_pesos_y_tiempos?.map(s => s.tiempo.toFixed(1)),
        },
        yAxis: {
            type: 'value',
            name: 'Fuerza (N)',
            boundaryGap: [0, '30%'],
            nameLocation: 'middle',
            nameGap: 50,
            axisLabel: {
                textStyle: { color: () => color_area },
            },
        },
        dataZoom: [
            {
            //    startValue: (doc.inicioFases? doc.inicioFases : 0), realtime: true,
            //    endValue: (doc.finFases? doc.finFases: 700), realtime: true,
            },
            {
                type: 'inside'
            }
        ],
        series: [
            {
                name: 'Píe izquierdo',
                type: 'line',
                symbol: 'none',
                data: pruebas[0]?.sistema_de_pesos_y_tiempos?.map(s => s.peso.izq),
                emphasis: {
                    focus: 'series'
                },
            },
            {
                name: 'Píe derecho',
                type: 'line',
                symbol: 'none',
                data: pruebas[0]?.sistema_de_pesos_y_tiempos?.map(s => s.peso.der)
                , emphasis: {
                    focus: 'series'
                },
            },
            {
                name: 'Combinado',
                type: 'line',
                backgroundColor: '#F39C12',
                smooth: true,
                data: pruebas[0]?.sistema_de_pesos_y_tiempos?.map(d => d.peso.combinada),
                emphasis: {
                    focus: 'series'
                },
                
                markArea: {
                    itemStyle: {
                        opacity: .5,

                    },
                    label: {
                        rotate: 45,
                        position: 'insideTopLeft',
                        color: color_area
                    },
                    data: (
                        pruebas[0]?.fases && recalculando === false ? pruebas[0]?.fases : newfases).map(f => ([
                        {
                            name: f.nombre,
                            xAxis: f.inicio+(recalculando? inicioFases : pruebas[0].inicioFases),
                            itemStyle: {
                                color: f.color,
                                opacity: .5
                            }
                        },
                        {
                            xAxis: f.fin+(recalculando? inicioFases : pruebas[0].inicioFases)
                        }
                    ]))

                }
            }
        ]
    }

    const descargarGrafica = () => {
            return new Promise((resolve) => {
                setColorArea('black')
                setTimeout(() => {
                    const img = grafica.current.getEchartsInstance().getDataURL({
                        pixelRatio: 2,
                        backgroundColor: '#fff',
                        height: 600,
                        theme:'light',
                        excludeComponents: ['dataZoom', 'toolbox']
                    })
                    setColorArea('whitesmoke')
                    resolve(img)
                }, 500)
            })
        }
    
    const generarGrafica = async (xData, yData, text) => {
            return await new Promise((resolve) => {
                // Crear un contenedor temporal para la gráfica
                const chartContainer = document.createElement('div');
                chartContainer.style.width = '800px';
                chartContainer.style.height = '600px';
                document.body.appendChild(chartContainer);
        
                // Inicializar la instancia de ECharts con el tema 'dark2'
                const chart = echarts.init(chartContainer, 'dark2');
        
                // Configuración de la gráfica
                const opciones_grafica = {
                    title: {
                        left: 'center',
                        text: '{title} vs Tiempo(s)'.replace('{title}', text),
                      },
                    tooltip: {
                        trigger: 'axis', // Mostrar información al pasar el mouse
                        axisPointer: {
                            type: 'cross'
                        }
                    },
                    xAxis: {
                        type: 'category', // Tipo categoría para el eje x
                        name: 'Tiempo (s)',
                        nameLocation: 'middle',
                        nameGap: 40,
                        axisLabel: {
                            color: 'whitesmoke',
                        },
                        data: xData.map(x => x.toFixed(2)), // Convertir xData a strings con 2 decimales
                    },
                    yAxis: {
                        type: 'value', // Tipo valor para el eje y
                        name: text,
                        nameLocation: 'middle',
                        nameGap: 50,
                        axisLabel: {
                            color: 'whitesmoke',
                        },
                    },
                    series: [
                        {
                            name: 'Fuerza vs Tiempo',
                            type: 'line',
                            smooth: true,
                            symbol: 'circle', // Mostrar puntos en la línea
                            data: yData, // Aquí se grafican los valores de Y
                            emphasis: {
                                focus: 'series'
                            },
                            itemStyle: {
                                color: '#F39C12'
                            }
                        }
                    ]
                };
        
                // Establecer las opciones y renderizar la gráfica
                chart.setOption(opciones_grafica);
        
                // Esperar un breve momento antes de capturar la imagen
                setTimeout(() => {
                    const img = chart.getDataURL({
                        pixelRatio: 2,
                        backgroundColor: '#191919',
                        height: 600,
                        excludeComponents: ['dataZoom', 'toolbox']
                    });
        
                    // Remover el contenedor temporal
                    document.body.removeChild(chartContainer);
        
                    resolve(img);
                }, 500);
            });
        };
    
    const descargar = async () => {
            setCargando(true)
            console.log(pruebas[0].datos)
            const img = await descargarGrafica()
            const img1 = await generarGrafica(pruebas[0].datos[0].aceleracion[1], pruebas[0].datos[0].aceleracion[0], 'Aceleración (m/s²)')
            const img2 = await generarGrafica(pruebas[0].datos[0].velocidad[1], pruebas[0].datos[0].velocidad[0], 'Velocidad (m/s)')
            const img3 = await generarGrafica(pruebas[0].datos[0].desplazamiento[1], pruebas[0].datos[0].desplazamiento[0], 'Desplazamiento (m)')
            const img4 = await generarGrafica(pruebas[0].datos[0].impulso[1], pruebas[0].datos[0].impulso[0], "Impulso (N·s)")
            
            const listImg = [img,img1,img2,img3,img4]
            const atleta = atletas[0]
            
            if(pruebas[0]){
                const prueba = pruebas[0]
                const { data } = await API.post(`prueba/descargar`,{listImg,prueba,atleta },{responseType: 'blob'})
                const href = window.URL.createObjectURL(data);
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', 'file.pdf');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(href);
            }
            
    
            
            setCargando(false)
        }
    const descargarData = async () => {
            setCargando(true)
            const { data } =  descargarCSV()
            const href = window.URL.createObjectURL(data);
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', `${atletas[0]?.nombre} ${pruebas[0]?.tipoPrueba} ${dayjs(pruebas[0].prueba.createdAt).format("DD-MM-YYYY")} ${pruebas[0]?.momento}.csv`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(href);
            setCargando(false)
        }
    const descargarCSV = () => {
            // Comprobar si existe "sistema_de_pesos_y_tiempos"
            if (!pruebas[0]?.sistema_de_pesos_y_tiempos) {
              console.error("No hay datos para descargar");
              return;
            }
          
            // Preparar los datos para el CSV
            const datosCSV = [["Tiempo", "Peso Izquierdo", "Peso Derecho"]];
            for (const elemento of pruebas[0]?.sistema_de_pesos_y_tiempos) {
              datosCSV.push([elemento.tiempo, elemento.peso.izq, elemento.peso.der]);
            }
          
            // Convertir los datos a formato CSV
            const csv = datosCSV.map(fila => fila.join(",")).join("\n");
          
            // Crear un blob con los datos CSV
            const blob = new Blob([csv], { type: "text/csv" });
          
       
            return { data: blob }
          }
    const reCalcularParametros   = async () => {
            setreCalculando(true)  
            const instanciaECharts = grafica.current.getEchartsInstance();
            const opcionesGrafico = instanciaECharts.getOption();
          
            //inicioFases = opcionesGrafico.dataZoom[0].startValue
            //console.log(`Inicio:`, opcionesGrafico.dataZoom[0].startValue, `fin:`,opcionesGrafico.dataZoom[0].endValue);
            setInicioFases(opcionesGrafico.dataZoom[0].startValue)
            setFinFases(opcionesGrafico.dataZoom[0].endValue)
                
            if (!pruebas[0]?.sistema_de_pesos_y_tiempos) {
                console.error("No hay datos para descargar");
                return;
              }
    
            
            if (pruebas[0].impresion === "CMJ" || pruebas[0]?.tipoPrueba === "CMJ"){
              
                const subset1 = pruebas[0]?.sistema_de_pesos_y_tiempos.slice(opcionesGrafico.dataZoom[0].startValue, opcionesGrafico.dataZoom[0].endValue)
                const { fases: fases_calculadas, datos: datos_calculados , parametros: parametros_calculados} = await runCMJ(subset1)
                const newParametrosCalculados = [...parametros_calculados]
                setParametros(newParametrosCalculados)
                setFases(fases_calculadas)
                setDatosGraficar(datos_calculados)
                //console.log(datos_calculados)
                //console.log(parametros)
            
            }
            else{
                
                const subset1 = pruebas[0]?.sistema_de_pesos_y_tiempos.slice(opcionesGrafico.dataZoom[0].startValue, opcionesGrafico.dataZoom[0].endValue)
                const { fases: fases_calculadas, parametros: parametros_calculados } = await runNordic(subset1)
                setParametros(parametros_calculados)
                setFases(fases_calculadas)
               
            }
            //console.log("Data",doc.sistema_de_pesos_y_tiempos)
            //console.log("Subset",subset1)
            //console.log("Fases",fases_calculadas)
            //console.log(doc)
            
            setreCalculando(false)
        }    
    const guardarnewParametros = async () => {
            try {
                setCargando(true)
                const url = `prueba/${id}`
                const method = "patch"
                const data = {
                    // ...extra,
                    //atleta: atleta,
                    //datos_brutos: datos_brutos.current,
                    //video,
                    //sistema_de_pesos_y_tiempos,
                    fases: [ ...newfases ],
                    datos: [ ...datosGraficar ],
                    parametros: [ ...newparametros ],
                    inicioFases,
                    finFases,
                    //tipoPrueba: "CMJ",
                }
                await API({
                    url,
                    method,
                    data
                })
                notification.success({ description: 'Prueba editada' })
                //navigate('/pruebas')
            } catch (err) {
                notification.error({
                    description: err?.response?.data?.mensaje || err.toString()
                })
            } finally {
                setCargando(false)
                setTimeout(() => {  window.location.reload() ; }, 1200);
            }
    
        }

        let parametros = pruebas[0]?.parametros || []
        let fases = pruebas[0]?.fases || []
    
        if (muestra_seleccionada) {
    
            parametros = parametros.map((p, i) => {
                if (muestra_seleccionada?.parametros[i]) {
                    p.media = muestra_seleccionada?.parametros[i].valor
                    p.porcentaje = ((p.valor / p.media) - 1) * 100
                    p.dif = p.valor - p.media
    
                }
                return p
            })
        }
    return (
    <>  
        <Flex align="center" justify="space-around">
            {cargando ? <ThreeDot color="#dcc630" size="medium" text="" textColor="" /> :
            <Button type="primary" icon={<FilePdfFilled />} onClick={descargar}>Descargar Resumen</Button>
            }
            <Button type="primary" icon={<DatabaseOutlined />} onClick={descargarData}>Descargar Data</Button>
                
        </Flex>
            
        
        <Typography.Title style={{ textAlign: 'center' }}>{pruebas[0]?.tipoPrueba || ''} </Typography.Title>
        <br></br>
        <div style={{ height: 600 }}>
            <ECharts ref={grafica} lazyUpdate={true} theme={'dark2'} option={opciones_grafica} style={{ width: '100%', height: '100%' }} />
        </div>

        <Flex gap="middle" justify="space-between">
            {
                newfases?.map((f, i) => <Card key={i} style={{ flex: 1 }}>
                <Typography.Title level={5}>{f.nombre}</Typography.Title>
                <Typography.Text>{f?.tiempo?.duracion.toFixed(2)*1000} ms</Typography.Text>
                </Card>)
            }
            </Flex>
            <br></br>
            <br></br>
            <Flex gap="middle" justify="space-around">
               
                {<SeleccionarMuestra onSeleccionar={setMuestraSeleccionada} tipe={pruebas[0]?.tipoPrueba} />}
                {
                    recalculando ? <ThreeDot color="#dcc630" size="medium" text="" textColor="" /> :
                    <Button type="primary"  onClick={reCalcularParametros}>Recalcular Parámetros</Button>
                }
                {
                    cargando ? <ThreeDot color="#dcc630" size="medium" text="" textColor="" /> :
                    <Button type="primary"  onClick={guardarnewParametros}>Guardar Parámetros</Button>
                    }
                
            </Flex>
            
            <br></br>
                <TableParamsPrueba newparametros={newparametros} muestra_seleccionada={muestra_seleccionada} recalculando={recalculando} />
            <br></br>

    </>
    )

}