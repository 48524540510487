export const optionsParametrosCmj = [
    {
        "nombre": 'Porcentaje fase descarga',
        "label": 'Porcentaje fase descarga',
        "value": "0"
    },
    {
        "nombre": "RSI",
        "label": "RSI",
        "value": "1"
    },
    {
        "nombre": "Tiempo en despegar",
        "label": "Tiempo en despegar",
        "value": "2"
    },
    {
        "nombre": "Fuerza promedio de frenado",
        "label": "Fuerza promedio de frenado",
        "value": "3"
    },
    {
        "nombre": "Fuerza promedio de frenado pierna izq.",
        "label": "Fuerza promedio de frenado pierna izq.",
        "value": "4"
    },
    {
        "nombre": "Fuerza promedio de frenado pierna der.",
        "label": "Fuerza promedio de frenado pierna der.",
        "value": "5"
    },
    {
        "nombre": "Fuerza pico de frenado pierna der.",
        "label": "Fuerza pico de frenado pierna der.",
        "value": "6"
    },
    {
        "nombre": "Fuerza pico de frenado pierna izq.",
        "label": "Fuerza pico de frenado pierna izq.",
        "value": "7"
    },
    {
        "nombre": "Potencia promedio de frenado",
        "label": "Potencia promedio de frenado",
        "value": "8"
    },
    {
        "nombre": "Velocidad promedio de frenado",
        "label": "Velocidad promedio de frenado",
        "value": "9"
    },
    {
        "nombre": "Fuerza promedio de frenado relativa",
        "label": "Fuerza promedio de frenado relativa",
        "value": "10"
    },
    {
        "nombre": "Potencia promedio de frenado relativa",
        "label": "Potencia promedio de frenado relativa",
        "value": "11"
    },
    {
        "nombre": "Impulso de frenado",
        "label": "Impulso de frenado",
        "value": "12"
    },
    {
        "nombre": "Duración frenado",
        "label": "Duración frenado",
        "value": "13"
    },
    {
        "nombre": "% duración frenado",
        "label": "% duración frenado",
        "value": "14"
    },
    {
        "nombre": "RFD",
        "label": "RFD",
        "value": "15"
    },
    {
        "nombre": "Fuerza pico de frenado",
        "label": "Fuerza pico de frenado",
        "value": "16"
    },
    {
        "nombre": "Potencia pico de frenado",
        "label": "Potencia pico de frenado",
        "value": "17"
    },
    {
        "nombre": "Velocidad pico de frenado",
        "label": "Velocidad pico de frenado",
        "value": "18"
    },
    {
        "nombre": "Fuerza pico relativa de frenado",
        "label": "Fuerza pico relativa de frenado",
        "value": "19"
    },
    {
        "nombre": "Potencia pico relativa de frenado",
        "label": "Potencia pico relativa de frenado",
        "value": "20"
    },
    {
        "nombre": "Impulso pico relativa de frenado",
        "label": "Impulso pico relativa de frenado",
        "value": "21"
    },
    {
        "nombre": "Promedio RDF de frenado pierna izq.",
        "label": "Promedio RDF de frenado pierna izq.",
        "value": "22"
    },
    {
        "nombre": "Promedio RDF de frenado pierna der.",
        "label": "Promedio RDF de frenado pierna der.",
        "value": "23"
    },
    {
        "nombre": "Impulso positivo",
        "label": "Impulso positivo",
        "value": "24"
    },
    {
        "nombre": "Profundidad de contramovimiento",
        "label": "Profundidad de contramovimiento",
        "value": "25"
    },
    {
        "nombre": "Fuerza a minimo desplazamiento",
        "label": "Fuerza a minimo desplazamiento",
        "value": "26"
    },
    {
        "nombre": "Relación de impulsos",
        "label": "Relación de impulsos",
        "value": "27"
    },
    {
        "nombre": "Fuerza relativa a minimo desplazamiento",
        "label": "Fuerza relativa a minimo desplazamiento",
        "value": "28"
    },
    {
        "nombre": "Rigidez de contramivimiento",
        "label": "Rigidez de contramivimiento",
        "value": "29"
    },
    {
        "nombre": "Fuerza promedio de propulsión",
        "label": "Fuerza promedio de propulsión",
        "value": "30"
    },
    {
        "nombre": "Fuerza promedio de propulsión izq",
        "label": "Fuerza promedio de propulsión izq",
        "value": "31"
    },
    {
        "nombre": "Fuerza promedio de propulsión der",
        "label": "Fuerza promedio de propulsión der",
        "value": "32"
    },
    {
        "nombre": "Potencia promedio de propulsión",
        "label": "Potencia promedio de propulsión",
        "value": "33"
    },
    {
        "nombre": "Velocidad promedio de propulsión",
        "label": "Velocidad promedio de propulsión",
        "value": "34"
    },
    {
        "nombre": "Fuerza promedio de propulsion relativa",
        "label": "Fuerza promedio de propulsion relativa",
        "value": "35"
    },
    {
        "nombre": "Potencia promedio de propulsión relativa",
        "label": "Potencia promedio de propulsión relativa",
        "value": "36"
    },
    {
        "nombre": "Fuerza pico de propulsión",
        "label": "Fuerza pico de propulsión",
        "value": "37"
    },
    {
        "nombre": "Potencia pico de propulsion",
        "label": "Potencia pico de propulsion",
        "value": "38"
    },
    {
        "nombre": "Fuerza pico relativa de propulsion",
        "label": "Fuerza pico relativa de propulsion",
        "value": "39"
    },
    {
        "nombre": "Potencia pico relativa de propulsion",
        "label": "Potencia pico relativa de propulsion",
        "value": "40"
    },
    {
        "nombre": "Impulso de propulsión",
        "label": "Impulso de propulsión",
        "value": "41"
    },
    {
        "nombre": "Fase propulsion duracion",
        "label": "Fase propulsion duracion",
        "value": "42"
    },
    {
        "nombre": "Fase propulsion duracion",
        "label": "Fase propulsion duracion",
        "value": "43"
    },
    {
        "nombre": "Impulso relativo de propulsión",
        "label": "Impulso relativo de propulsión",
        "value": "44"
    },
    {
        "nombre": "Fuerza promedio de propulsión izq",
        "label": "Fuerza promedio de propulsión izq",
        "value": "45"
    },
    {
        "nombre": "Fuerza promedio de propulsión der",
        "label": "Fuerza promedio de propulsión der",
        "value": "46"
    },
    {
        "nombre": "Fuerza propulsiva der en pico de fuerza combinado",
        "label": "Fuerza propulsiva der en pico de fuerza combinado",
        "value": "47"
    },
    {
        "nombre": "Fuerza propulsiva izq en pico de fuerza combinado",
        "label": "Fuerza propulsiva izq en pico de fuerza combinado",
        "value": "48"
    },
    {
        "nombre": "Velocidad de despegue",
        "label": "Velocidad de despegue",
        "value": "49"
    },
    {
        "nombre": "Altura de salto",
        "label": "Altura de salto",
        "value": "50"
    },
    {
        "nombre": "Momentum de salto",
        "label": "Momentum de salto",
        "value": "51"
    },
    {
        "nombre": "Tiempo de vuelo",
        "label": "Tiempo de vuelo",
        "value": "52"
    },
    {
        "nombre": "Asimetría de fuerza de frenado promedio (Izquierda/derecha)",
        "label": "Asimetría de fuerza de frenado promedio (Izquierda/derecha)",
        "value": "53"
    },
    {
        "nombre": "Asimetría de fuerza de propulsión promedio (Izquierda/Derecha)",
        "label": "Asimetría de fuerza de propulsión promedio (Izquierda/Derecha)",
        "value": "54"
    },
    {
        "nombre": "Asimetría fuerza de frenado pico Izquierda/Derecha",
        "label": "Asimetría fuerza de frenado pico Izquierda/Derecha",
        "value": "55"
    },
    {
        "nombre": "Asimetría de fuerza de propulsión pico Izquierda/Derecha",
        "label": "Asimetría de fuerza de propulsión pico Izquierda/Derecha",
        "value": "56"
    },
    {
        "nombre": "Asimetría de fuerza de propulsión pico Derecha/Izquierda",
        "label": "Asimetría de fuerza de propulsión pico Derecha/Izquierda",
        "value": "57"
    },
    {
        "nombre": "Asimetría de potencia de propulsión pico Derecha/Izquierda",
        "label": "Asimetría de potencia de propulsión pico Derecha/Izquierda",
        "value": "58"
    },
    {
        "nombre": "Asimetría de potencia de propulsión pico Izquierda/Derecha",
        "label": "Asimetría de potencia de propulsión pico Izquierda/Derecha",
        "value": "59"
    },
    {
        "nombre": "Asimetría de impulso de propulsión pico Derecha/Izquierda",
        "label": "Asimetría de impulso de propulsión pico Derecha/Izquierda",
        "value": "60"
    },
    {
        "nombre": "Asimetría de impulso de propulsión pico Izquierda/Derecha",
        "label": "Asimetría de impulso de propulsión pico Izquierda/Derecha",
        "value": "61"
    }
]

export const optionsParametrosNordic = [
    {
        "nombre": 'Pico de Fuerza pierna der.',
        "label": 'Pico de Fuerza pierna der.',
        "value": "0"
    },
    {
        "nombre": "Pico de Fuerza pierna izq.",
        "label": "Pico de Fuerza pierna izq.",
        "value": "1"
    },
    {
        "nombre": "Pico de Fuerza pierna der + izq.",
        "label": "Pico de Fuerza pierna der + izq.",
        "value": "2"
    },
    {
        "nombre": "Asimetria der/izq.",
        "label": "Asimetria der/izq.",
        "value": "3"
    },
    {
        "nombre": "Porcentaje Asimetria der/izq.",
        "label": "Porcentaje Asimetria der/izq.",
        "value": "4"
    },
]