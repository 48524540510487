import { Breadcrumb, Button, Dropdown, Flex, Form, Table,notification,Select } from "antd"
import { ClockCircleOutlined, DeleteOutlined, DotChartOutlined, DownOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import useSesion from "../../../Hooks/Sesion";
import { useInstitucion } from "../../../Hooks/Institucion/useInstitucion";
import { useAtleta } from "../../../Hooks/Atleta/useAtleta";
import { Atom } from "react-loading-indicators";

const AtletaListado = () => {
    const navigate = useNavigate()
    const {usuario} = useSesion()
    const {instituciones, getInstitucion, getAllInstitucion} = useInstitucion()
    const {atletas, getAtletas, deleteAtleta} = useAtleta()
    const [cargando, setCargando] = useState(false)
    

    const miga = [
        {
            title: <Link to="/">Inicio</Link>
        },
        {
            title: 'Atletas'
        }
    ]

    const menu = [
        {
            label: 'Editar',
            key: 1,
            icon: <EditOutlined />
        },
        {
            label: 'Iniciar CMJ',
            key: 2,
            icon: <ClockCircleOutlined />
        },
        {
            label: 'Iniciar Nordic Curl',
            key: 3,
            icon: <ClockCircleOutlined />
        },
        {
            label: 'Tendencias',
            key: 4,
            icon: <DotChartOutlined />
        },
        {
            label: 'Borrar',
            key: 5,
            icon: <DeleteOutlined />,
            danger: true
        }
    ]

    const Acciones = ({ atleta }) => {
        const click = ({ key }) => {

            if (key == '1') {
                navigate(`/atleta/${atleta._id}/actualizar`)
                return
            }

            if (key == '2') {
                navigate(`/atleta/${atleta._id}/iniciar-prueba/cmj`)
                return
            }

            if (key == '3') {
                navigate(`/atleta/${atleta._id}/iniciar-prueba/nordic`)
                return
            }

            if (key == '4') {
                navigate(`/atleta/${atleta._id}/tendencias`)
                return
            }
            if (key =='5'){
                deleteAtleta(atleta._id)
                cargar({})
                return
            }
        }
        return <Dropdown.Button icon={<DownOutlined />} menu={{ items: menu, onClick: click }}>Acciones</Dropdown.Button>
    }

    const cargarIns = async () => {
        setCargando(true)
        try {
            usuario?.rol === 'Admin' ? await getAllInstitucion() : await getInstitucion(usuario?.institucion)
        } catch (error) {
            notification.error({ description: error.response.data.mensaje })
            navigate('/atletas')
        }
        finally {
            setCargando(false);
        }
    }

    const cargar = async (param) => {
        try {
            setCargando(true)
            await getAtletas(param)
        } catch (error) {
            notification.error({ description: error.response.data.mensaje })
        }
        finally{
            setCargando(false)
        }
        
        
    }
  
    
    useEffect(() => {
        cargarIns()
        cargar({})
    }, [])

    return <div style={{ padding: 32 }}>
        <Flex gap="middle" align="center">
            <Breadcrumb items={miga} />
            <Link to='/atleta/guardar'><Button icon={<PlusOutlined />} /></Link>
        </Flex>
        <br></br>
        <Form onFinish={cargar}>
            <Form.Item name={'institucion'} label="Institución" >
                <Select 
                options={instituciones.map(i => ({ value: i._id, label: i.nombre }))}
                defaultValue={instituciones[usuario?.institucion]}
                allowClear={usuario?.rol === 'Admin'}
                />
            </Form.Item>
            <Button type="primary" htmlType="submit">Filtrar</Button>
        </Form>
        <br></br>
        {
            cargando ? <Flex align="center" justify="center">
                <Atom color="#32cd32" size="medium" text="" textColor="" />
            </Flex> : 
            <Table rowKey={'_id'} loading={cargando} dataSource={atletas} pagination={false}>
            <Table.Column title="Nombre" dataIndex={'nombre'} />
            <Table.Column title="Ide." dataIndex={'ide'} />
            <Table.Column title="Peso" dataIndex={'peso'} />
            <Table.Column title="Instituciones" render={(d) => (d.institucion ? d.institucion.nombre : 'Sin institución')} />
            <Table.Column title="Acciones" render={(d) => (<Acciones atleta={d} />)} />
        </Table>
        }
    </div>
}

export default AtletaListado