import { BankOutlined, ExperimentOutlined, PoweroffOutlined, TeamOutlined, UserOutlined, BugOutlined  } from "@ant-design/icons"
import { Card, Col, Flex, notification, Row, Typography } from "antd"
import { Link } from "react-router-dom"
import useSesion from "../../Hooks/Sesion"
import ValidarRol from "../../Componentes/ValidarRol"
import { useInstitucion } from "../../Hooks/Institucion/useInstitucion"
import { useEffect } from "react"


const Inicio = () => {
    const { logout } = useSesion()
    const { usuario } = useSesion()
    const { instituciones, getInstitucion } = useInstitucion()

    const cargar = async () => {
        try {
            await getInstitucion(usuario?.institucion)
        } catch (error) {
          
            notification.error({ description: error.response.data.mensaje })
        }
    }

    useEffect(() => {
        cargar()
        
    }, [])
    
    return <div style={{ margin: 64 }}>
        {/*<Typography.Title>SEFUM</Typography.Title>*/}
            <Flex justify="center" style={{ marginBottom: '24px' }}>
                <Col justify="center" align="center">

                    <img
                        width={200}
                        src="imagenes/logo.jpeg"
                        style={{ borderRadius: '10px' }}
                    />
                    <br /><br />

                    <Typography.Title level={2}>{instituciones[0]?.nombre}</Typography.Title>
                
                </Col>
            </Flex>


            <Row justify={'center'} gutter={[24, 24]} align="stretch">
                <Col xs={24} sm={12} md={6}>
                    <Link to="/atletas">
                        <Card style={{ textAlign: 'center' }}>
                            <TeamOutlined style={{ fontSize: '7.5em' }} />
                            <Typography.Title level={4}>Atletas</Typography.Title>
                        </Card>
                    </Link>
                </Col>
                <Col xs={24} sm={12} md={6}>
                    <Link to="/pruebas">
                        <Card style={{ textAlign: 'center' }}>
                            <ExperimentOutlined style={{ fontSize: '7.5em' }} />
                            <Typography.Title level={4}>Historico de pruebas</Typography.Title>
                        </Card>
                    </Link>
                </Col>
                <ValidarRol roles={['Admin']}>

                    <Col xs={24} sm={12} md={6}>
                        <Link to="/instituciones">
                            <Card style={{ textAlign: 'center' }}>
                                <BankOutlined style={{ fontSize: '7.5em' }} />
                                <Typography.Title level={4}>Instituciones</Typography.Title>
                            </Card>
                        </Link>
                    </Col>

                </ValidarRol>
                
                <ValidarRol roles={['Admin']}>
                <Col xs={24} sm={12} md={6}>
                    <Link to="/usuarios">
                        <Card style={{ textAlign: 'center' }}>
                            <UserOutlined style={{ fontSize: '7.5em' }} />
                            <Typography.Title level={4}>Usuarios</Typography.Title>
                        </Card>
                    </Link>
                </Col>
                </ValidarRol>
               
                <Col xs={24} sm={12} md={6}>
                    <Card style={{ textAlign: 'center' }} onClick={logout}>
                        <PoweroffOutlined style={{ fontSize: '7.5em' }} />
                        <Typography.Title level={4}>Cerrar</Typography.Title>
                    </Card>
                </Col>
            </Row>

            <Typography.Title level={2} onClick={(e) => e.preventDefault()}>
                Reportes
            </Typography.Title>
            <Row justify={'start'} gutter={[24, 24]} align="stretch">
                <Col xs={24} sm={12} md={6}>
                    <Link to="/reportes">
                        <Card style={{ textAlign: 'center' }}>
                            <BugOutlined style={{ fontSize: '7.5em' }} />
                            <Typography.Title level={4}>Reportar Error</Typography.Title>
                        </Card>
                    </Link>
                </Col>
            </Row>
            
        </div>
}

            export default Inicio