import { Header } from "antd/es/layout/layout";
import dayjs from "dayjs";
import { Flex, Typography } from "antd";

import { usePruebaContext } from "../../Hooks/Prueba/usepruebacontext";
export  function HeaderPruebaDetalle(){
    const {atletas, pruebas} = usePruebaContext()

    return(
        <>
        
        <Header>
            <Flex gap="middle" justify="space-between" align="center" style={{ height: '100%' }}>
                <Flex justify="space-around" align="center" style={{ width:'100%' }}>
                    <Typography.Text>Nombre: {atletas[0]?.nombre || 'Anonimo'}</Typography.Text>
                    <Typography.Text type="secondary">Peso: {atletas[0]?.peso}kg {(atletas[0]?.peso / 0.45359237).toFixed(2)}lbs</Typography.Text>
                    <Typography.Text>Momento: {pruebas[0]?.momento || ''}</Typography.Text>

                    <Typography.Text> Impresion:{pruebas[0]?.impresion || 'No se ingreso impresión profesional'}</Typography.Text>
                    <Typography.Text>Fecha: {dayjs(pruebas[0]?.createdAt).format("YYYY/MM/DD hh:mm a")}</Typography.Text>
            
                </Flex>
            </Flex>
        </Header>
        </>
    )
}

