import { Breadcrumb, Button, Flex, Popconfirm, Table, message, notification } from "antd"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import API from "../../../API"
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons"
import ValidarRol from "../../../Componentes/ValidarRol"
import AccesoProhibido from "../../../Componentes/AccesoProhibido"


const UsuarioListado=()=>{
    const [docs,setDocs] = useState([]) 
    const [cargando,setCagando] = useState(false)

    const miga = [
        {
            title: <Link to="/">Inicio</Link>
        },
        {
            title: 'Usuarios'
        }
    ]

    const cargar= async ()=>{
        try{
            setCagando(true)
            const {data} = await API('usuario')
            setDocs(data?.docs)
        }catch(err){
            notification.error({
                description:err?.response?.data?.mensaje || err.toString(),
                message:'Usuarios no cargados'
            })
        }finally{
            setCagando(false)
        }
    }

    const borrar=async (usuario)=>{
        try{
            setCagando(true)
            await API.delete(`usuario/${usuario._id}`)
            cargar()
        }catch(err){
            notification.error({
                description:err?.response?.data?.mensaje || err.toString(),
                message:'Usuarios no cargados'
            })
        }finally{
            setCagando(false)
        }
    }

    useEffect(()=>{
        cargar()
    },[])

    return <ValidarRol roles={['Admin']} FeedBack={AccesoProhibido}>
        <div style={{ padding: 32 }}>
        <Flex gap={'large'} align="center">
            <Breadcrumb items={miga}/>
            <Link to='/usuario'><Button icon={<PlusOutlined/>}>Nuevo usuario</Button></Link>
        </Flex>
        <br></br>
        <br></br>
        <Table pagination={false} dataSource={docs} loading={cargando} >
            <Table.Column title="Nombre" dataIndex={'nombre'}/>
            <Table.Column title="Username" dataIndex={'usuario'}/>
            <Table.Column title="Editar" render={(d)=><Link to={`/usuario/${d._id}`}><Button shape="circle" icon={<EditOutlined/>}/></Link>} />
            <Table.Column title="Borrar" render={(d)=><Popconfirm message="Confimar eliminación" onConfirm={()=>borrar(d)}><Button shape="circle" danger icon={<DeleteOutlined/>}/></Popconfirm>}/>
        </Table>
    </div>
    </ValidarRol>
}

export default UsuarioListado