import { Breadcrumb, Button, Card, Flex, Form, Input, InputNumber, Row, Select, notification, Col, Divider, Switch, DatePicker } from "antd"
import { DeleteOutlined } from '@ant-design/icons';
import { useEffect, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import { useForm } from "antd/es/form/Form";
import dayjs from "dayjs";
import useSesion from "../../../Hooks/Sesion";
import { useAtleta } from "../../../Hooks/Atleta/useAtleta";
import { Atom } from "react-loading-indicators";
import { useInstitucion } from "../../../Hooks/Institucion/useInstitucion"

const AtletaGuardar = () => {
    const navigate = useNavigate()
    const {usuario} = useSesion()
    const { id } = useParams()
    const [cargando, setCargando] = useState(false)
    const [form] = useForm()
    const {saveAtleta, updateAtleta, getAtleta, atletas, lesiones} = useAtleta()
    const {instituciones , getAllInstitucion,getInstitucion} = useInstitucion()
   
    const miga = [
        {
            title: <Link to="/">Inicio</Link>
        },
        {
            title: <Link to="/atletas">Atletas</Link>
        },
        {
            title: id ? 'Actualizar atleta' : 'Registrar nuevo atleta'
        }
    ]

    const guardar = async (data) => {
            console.log(data)
            setCargando(true)
            id ? await updateAtleta(id,data) : await saveAtleta(data)
            setCargando(false)
            navigate('/atletas')
    }

    const cargar = async () => {
        try {
            setCargando(true)
            await getAtleta(id)
        } catch (error) {
            notification.error({ description: error.response.data.mensaje })
            navigate('/atletas')
        } finally {
            setCargando(false)
        }
    }



    useEffect(() => {
        usuario?.rol === 'Admin' ? getAllInstitucion() : getInstitucion(usuario?.institucion) 
        if (id) {
            cargar()
        }
    }, [id])
    useEffect(() => {
        if (atletas.length > 0) {
            form.setFieldsValue({ ...atletas[0], lesiones });
        }
    }, [atletas, lesiones]);
    

    return <div style={{ padding: 32 }}>
        <Breadcrumb items={miga} />
        <br></br>
        {
            cargando ? 
            <Flex align="center" justify="center">
                <Atom color="#32cd32" size="medium" text="" textColor="" />
            </Flex> :
             <Flex align="center" justify="center" >
             <Card style={{ width: '90%', maxWidth: 960 }}>
                 <Form form={form} layout="vertical" onFinish={guardar}>
 
                     <Row gutter={[16, 16]}>
                         <Col xs={24} md={8}>
                             <Form.Item name={'nombre'} label="Nombre" rules={[{ required: true, message: 'Campo requerido' }]}>
                                 <Input style={{width:'100%'}} />
                             </Form.Item>
                         </Col>
                         <Col xs={24} md={8}>
                             <Form.Item name={'ide'} label="Identificación" rules={[{ required: true, message: 'Campo requerido' }]}>
                                 <InputNumber style={{width:'100%'}} />
                             </Form.Item>
                         </Col>
                         <Col xs={24} md={8}>
                             <Form.Item name={'sexo'} label="Sexo" rules={[{ required: true, message: 'Campo requerido' }]}>
                                 <Select style={{width:'100%'}} options={[{ value: 'Hombre', label: 'Hombre' }, { value: 'Mujer', label: 'Mujer' }, { value: 'N/A', label: 'N/A' }]} />
                             </Form.Item>
                         </Col>
                     </Row>
 
 
                     <Row gutter={[16, 16]}>
                         
                         <Col xs={24} md={8}>
                             <Form.Item name={'estatura'} label="Estatura (cm)" rules={[{ required: true, message: 'Campo requerido' }]}>
                                 <InputNumber style={{width:'100%'}} />
                             </Form.Item>
                         </Col>
                         <Col xs={24} md={8}>
                             <Form.Item name={'indice_masa_corporal'} label="IMC" rules={[{ required: true, message: 'Campo requerido' }]}>
                                 <InputNumber style={{width:'100%'}} />
                             </Form.Item>
                         </Col>
                         <Col xs={24} md={8}>
                             <Form.Item name={'institucion'} label="Institución" >
                                 <Select style={{width:'100%'}} 
                                 options={instituciones.map(i => ({ value: i._id, label: i.nombre }))}
                                 defaultValue={instituciones[usuario.institucion]} />
                             </Form.Item>
 
                         </Col>
                     </Row>
 
                     <Row gutter={[16, 16]}>
                        
                         <Col xs={24} md={8}>
                             <Form.Item name={'peso'} label="Peso (kg)">
                                 <InputNumber style={{ width: '100%' }} />
                             </Form.Item>
                         </Col>
                         <Col xs={24} md={8}>
                             <Form.Item name={'masa_magra'} label="Masa magra (kg)" rules={[{ required: true, message: 'Campo requerido' }]}>
                                 <InputNumber style={{width:'100%'}} />
                             </Form.Item>
                         </Col>
                         <Col xs={24} md={8}>
                             <Form.Item name={'longitud_pierna'} label="Lng. Pierna (cm)" rules={[{ required: true, message: 'Campo requerido' }]}>
                                 <InputNumber style={{width:'100%'}} />
                             </Form.Item>
                         </Col>
                     </Row>
 
                     <Row gutter={[16, 16]}>
                        
                         <Col xs={24} md={16}>
                             <Form.Item name={'distancia_cadera'} label="Distancia de cadera al piso en flexión de 90º" rules={[{ required: true, message: 'Campo requerido' }]}>
                                 <InputNumber style={{width:'100%'}} />
                             </Form.Item>
                         </Col>
                     </Row>
                     <Divider></Divider>
                     <Flex gap="middle" align="center">
                         <img src="https://images.unsplash.com/photo-1589698112632-f5dffc219c68?q=80&w=2532&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" width={128} style={{opacity:.3}}/>
                             <div style={{flex:1}}>
                         <Form.List  name={'lesiones'}>
                             {(fields, { add, remove }) => (
                                 <div>
                                     {fields.map((field, i) => (
                                         <Flex gap='middle' key={i} align="center">
                                             <Form.Item style={{ flex: 1 }} name={[field.name, 'nombre']} label="Lesión">
                                                 <Input />
                                             </Form.Item>
                                             <Form.Item style={{ flex: 1 }} name={[field.name, 'fecha']} label="fecha">
                                                 <DatePicker />
                                             </Form.Item>
                                             <Form.Item valuePropName="checked" name={[field.name, 'rehabilitacion']} label="Rehabilitado">
                                                 <Switch />
                                             </Form.Item>
                                             <Button danger onClick={() => remove(i)} shape="circle" icon={<DeleteOutlined />}></Button>
                                         </Flex>
                                     ))}
                                     <Button type="primary" onClick={() => add({ nombre: '', fecha: dayjs(), rehabilitacion: false })}>Registrar lesión</Button>
                                 </div>
                             )}
                         </Form.List>
                         </div>
                     </Flex>
 
                                         <br></br><br></br>
                     <Button loading={cargando} type="primary" htmlType="submit" >Guardar</Button>
                 </Form>
             </Card>
         </Flex>
        }
    </div>
}

export default AtletaGuardar