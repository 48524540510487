import { useState, useEffect } from "react";
import { Button, Flex, Popconfirm, Space, Table, Typography, notification } from "antd";
import { DeleteOutlined, EyeFilled } from "@ant-design/icons";
import dayjs from "dayjs";
import CrearGrupo from "../../Paginas/Prueba/Listado/Componentes/CrearGrupo";
import { Link } from "react-router-dom";
import { useForm } from "antd/es/form/Form";
import  {usePrueba}  from "../../Hooks/Prueba/usePrueba";
import useSesion from "../../Hooks/Sesion";
import  {FiltrosPruebas}  from "./FiltrosPruebas";

export const TablePruebas = ({cargando, setCargando}) =>{
    const [seleccionados, setSeleccionados] = useState([])
    const {usuario} = useSesion()
    const { filtro_prueba, setFiltroPrueba } = useSesion()
    const {pruebas,getPruebas,deletePrueba, cargarFecha} = usePrueba()
    const [form] = useForm()

    const cargar = async (payload) => {
        setCargando(true)
        setFiltroPrueba(prev => ({ ...prev, fecha: payload.fecha, instiucion: usuario?.rol === 'Admin' ? payload.institucion : usuario?.institucion }))
        await getPruebas(payload)
        setCargando(false)
        
    }

    const borrar=async (prueba)=>{
        try{
            setCargando(true)
            await deletePrueba(prueba)
            cargar(filtro_prueba)
            notification.success({
                message:'Prueba borrada'
            })
        }catch(err){
            notification.error({
                description:err?.response?.data?.mensaje || err.toString(),
                message:'Prueba no borrada'
            })
        }finally{
            await getPruebas(filtro_prueba)
            setCargando(false)
        }
    }

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSeleccionados([...selectedRows.map(s => s._id)])
        }
    };

    useEffect(() => {   
        cargarFecha(form, setFiltroPrueba)
    }, [])
    return(
        <>
            <FiltrosPruebas cargar={cargar} form={form} cargando={cargando} setCargando={setCargando}/>
            
            <Space direction="vertical" style={{ width: '100%' }} align="center">
                <Typography.Title level={1} style={{ fontSize: '28px', fontWeight: 'bold', textAlign: 'center' }}>
                    Pruebas
                </Typography.Title>
            </Space>
            <Table 
                rowKey={'_id'} 
                rowSelection={{
                    type: 'checkbox',
                    ...rowSelection,
                }} 
                loading={cargando} 
                dataSource={pruebas} 
                pagination={false}
                scroll={{ x: 'max-content', y: 300 }} // Desplazamiento horizontal y vertical
            >
                <Table.Column title="Fecha" dataIndex={'createdAt'} render={(v) => dayjs(v).format("YYYY/MM/DD hh:mm a")} width="20%"/>
                <Table.Column title="Tipo" dataIndex={'tipoPrueba'} />
                <Table.Column title="Momento" dataIndex={'momento'} />
                <Table.Column title="Identificación" dataIndex={'atleta'} render={(d) => d?.ide} />
                <Table.Column title="Atleta" dataIndex={'atleta'} render={(a) => a?.nombre} />
                <Table.Column title="Acciones" render={(d) => (<Flex gap={'middle'}>
                    <Link to={`/prueba/${d._id}`}><Button shape="circle" icon={<EyeFilled />} /></Link>
                    
                        <Popconfirm title="No prodras recuperar los datos de esta prueba" onConfirm={()=>borrar(d)}>
                        <Button danger shape="circle" icon={<DeleteOutlined />} />
                        </Popconfirm>
                
                </Flex>)} />
            </Table>
            <br></br>
        
            <CrearGrupo registros={seleccionados} isMuestra={false} />
        </>
    )
}
