import { Breadcrumb } from "antd"
import { useState } from "react"
import { Link } from "react-router-dom"

import { TablePruebas } from "../../../Componentes/Pruebas/TablePruebas";
import { TableMuestras } from "../../../Componentes/Muestras/TableMuestras";


const PruebaListado = () => {
    const [cargando, setCargando] = useState(false)
    
    const miga = [
        {
            title: <Link to="/">Inicio</Link>
        },
        {
            title: 'Pruebas realizadas'
        }
    ]

    return <div style={{ padding: 32 }}>
        <Breadcrumb items={miga} />
   
        <TablePruebas setCargando={setCargando} cargando={cargando}/>

        <br></br>
        <TableMuestras cargando={cargando} setCargando={setCargando}/>
        
    </div>
}

export default PruebaListado