import { Table } from "antd"
export const TableCuadrantes = ({cargando,doc,name,parametrosT}) =>{
    return(
        <>
               <Table 
                rowKey={'_id'} 
                loading={cargando} 
                dataSource={doc} 
                pagination={false} 
                style={{ width: 1500 }}
            >
                <Table.Column 
                    title={<div style={{ textAlign: 'center' }}>Puesto</div>} 
                    dataIndex={"id"} 
                    width="20%" 
                    render={(text) => <div style={{ textAlign: 'center' }}>{text}</div>} 
                />
                <Table.Column 
                    title={<div style={{ textAlign: 'center' }}>{name}</div>} 
                    dataIndex={"nombre"} 
                    width="20%" 
                    render={(text) => <div style={{ textAlign: 'center' }}>{text}</div>} 
                />
                <Table.Column 
                    title={<div style={{ textAlign: 'center' }}>{parametrosT[0]}</div>} 
                    dataIndex={"param1"} 
                    width="20%" 
                    render={(text) => <div style={{ textAlign: 'center' }}>{text}</div>} 
                />
                <Table.Column 
                    title={<div style={{ textAlign: 'center' }}>{parametrosT[1]}</div>} 
                    dataIndex={"param2"} 
                    width="20%" 
                    render={(text) => <div style={{ textAlign: 'center' }}>{text}</div>} 
                />
                <Table.Column 
                    title={<div style={{ textAlign: 'center' }}>Cuadrante</div>} 
                    dataIndex={"cuadrante"} 
                    width="30%" 
                    render={(text) => <div style={{ textAlign: 'center' }}>{text}</div>} 
                />
            </Table>
        </>
    )
}