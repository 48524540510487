import { useState, useEffect } from "react";
import { Button, Flex, Popconfirm, Space, Table, Typography, notification } from "antd";
import { DeleteOutlined, EyeFilled } from "@ant-design/icons";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import CrearGrupo from "../../Paginas/Prueba/Listado/Componentes/CrearGrupo";
import ValidarRol from "../ValidarRol";
import { useMuestra } from "../../Hooks/Muestra/useMuestra";

export const TableMuestras = ({cargando, setCargando}) =>{
    const [muestrasSeleccionados, setMuestrasSeleccionadas] = useState([])
    const {muestras, loadMuestras, deleteMuestra} = useMuestra()



    const borrarMuestra=async (muestra)=>{
        try{
            setCargando(true)
            deleteMuestra(muestra)
            loadMuestras()
        }catch(err){
            notification.error({
                description:err?.response?.data?.mensaje || err.toString(),
                message:'Prueba no borrada'
            })
        }finally{
            await loadMuestras()
            setCargando(false)
        }
    }

    const rowSelectionMuestras = {
        onChange: (selectedRowKeys, selectedRows) => {
            setMuestrasSeleccionadas([...selectedRows.map(s => s._id)])
        }
    };
    
    useEffect(() => {
        loadMuestras()
    }, [])

   return(
    <>
        <Space direction="vertical" style={{ width: '100%' }} align="center">
            <Typography.Title level={1} style={{ fontSize: '28px', fontWeight: 'bold', textAlign: 'center' }}>
                Muestras
            </Typography.Title>
        </Space>
        <Table 
            rowKey={'_id'} 
            rowSelection={{
                type: 'checkbox',
                ...rowSelectionMuestras,
            }} 
            loading={cargando} 
            dataSource={muestras} 
            pagination={false}
            scroll={{ x: 'max-content', y: 300 }} // Desplazamiento horizontal y vertical
            >
                <Table.Column title="Fecha" dataIndex={'createdAt'} render={(v) => dayjs(v).format("YYYY/MM/DD hh:mm a")} width="20%"/>
                <Table.Column title="Tipo" dataIndex={'tipoPrueba'} width="30%"/>
                <Table.Column title="Nombre" dataIndex={'nombre'}  width="20%"/>
                
                
                <Table.Column title="Acciones" render={(m) => (
                    <Flex gap={'middle'}>
                        <Link to={`/muestra/${m._id}`}><Button shape="circle" icon={<EyeFilled />} /></Link>
                        <ValidarRol roles={['Admin']}>
                            <Popconfirm title="No podrás recuperar los datos de esta muestra" onConfirm={() => borrarMuestra(m)}>
                                <Button danger shape="circle" icon={<DeleteOutlined />} />
                            </Popconfirm>
                        </ValidarRol>
                        
                    </Flex>
                )} />
            </Table>
        <br></br>
        <CrearGrupo registros={muestrasSeleccionados} isMuestra  />
        </>
   )

}
