import { useContext } from "react"
import { PruebaContext } from "../../Contexts/Prueba/Prueba"


export const usePruebaContext = ()=>{

    const contextPrueba = useContext(PruebaContext)

    if(contextPrueba == undefined){
        throw new Error("usePruebaContext debe ser usado dentro de PruebaContextProvider")
    }

    return contextPrueba
}