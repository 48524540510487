import { notification, Breadcrumb, Flex, Typography, Card, Table, theme, Button } from "antd"
import { useEffect, useRef, useState } from "react"
import { useNavigate, useParams, Link } from "react-router-dom"
import ECharts from 'echarts-for-react';
import API, { BASE_URL } from "../../../API"
import { Header } from "antd/es/layout/layout";
import dayjs from "dayjs";
import { runCMJ } from "../CMJ/Guardar/cmj";
import { runNordic } from "../Nordic/Guardar/nordic";
//import VideoFases from "../../../Componentes/VideoFases";
import SeleccionarMuestra from "../../../Componentes/SeleccionarMuestra";
//import calcularParametros from "../CMJ/Guardar/Rep";
import { FilePdfFilled, DatabaseOutlined} from "@ant-design/icons";
import { CSVLink } from 'react-csv'; // Importar CSVLink
import useSesion from "../../../Hooks/Sesion";


const { useToken } = theme;

const MuestraDetalle = () => {
    const { token } = useToken()
    const {usuario} = useSesion()
    const navigate = useNavigate()
    const { id } = useParams()
    const [doc, setDoc] = useState({})
    const [atleta, setAtleta] = useState({})
    const [muestra_seleccionada, setMuestraSeleccionada] = useState(null)
    const [cargando, setCargando] = useState(false)
    const grafica = useRef(null)
    const [color_area, setColorArea] = useState('whitesmoke')

    const [newfases, setFases] = useState([])
    const [newparametros, setParametros] = useState([])
    const [inicioFases, setInicioFases] = useState(0)
    const [finFases, setFinFases] = useState(0)

    const [recalculando, setreCalculando] = useState(false)

    //const [startValue, setStartValue] = useState(0);


    const cargar = async () => {
        try {
            setCargando(true)
            const { data } = await API(`muestra/${id}`)
            setDoc(data?.doc)
            //console.log(data?.doc)
        } catch (err) {
            notification.error({ description: err?.response?.data?.mensaje || err.toString() })
            navigate('/pruebas')
        } finally {
            setCargando(false)
        }
    }

    const descargarGrafica = () => {
        return new Promise((resolve) => {
            setColorArea('black')
            setTimeout(() => {
                const img = grafica.current.getEchartsInstance().getDataURL({
                    pixelRatio: 2,
                    backgroundColor: '#fff',
                    height: 600,
                    theme:'light',
                    excludeComponents: ['dataZoom', 'toolbox']
                })
                setColorArea('whitesmoke')
                resolve(img)
            }, 500)
        })

    }

    const descargar = async () => {
        //const img = await descargarGrafica()
        const { data } = await API.post(`prueba/descargar`,{doc,atleta},{responseType: 'blob'})

        const href = window.URL.createObjectURL(data);
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', 'file.pdf');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(href);
    }
    const reCalcularParametros = async () => {
        setreCalculando(true)
      
        const instanciaECharts = grafica.current.getEchartsInstance();
        const opcionesGrafico = instanciaECharts.getOption();
        //inicioFases = opcionesGrafico.dataZoom[0].startValue
        //console.log(`Inicio:`, opcionesGrafico.dataZoom[0].startValue, `fin:`,opcionesGrafico.dataZoom[0].endValue);
        setInicioFases(opcionesGrafico.dataZoom[0].startValue)
        setFinFases(opcionesGrafico.dataZoom[0].endValue)
            
        if (!doc?.sistema_de_pesos_y_tiempos) {
            console.error("No hay datos para descargar");
            return;
          }
        if (doc.impresion === "CMJ" || doc.tipoPrueba === "CMJ"){

            const subset1 = doc?.sistema_de_pesos_y_tiempos.slice(opcionesGrafico.dataZoom[0].startValue, opcionesGrafico.dataZoom[0].endValue)
            const { fases: fases_calculadas, parametros: parametros_calculados } = await runCMJ(subset1)
            const newParametrosCalculados = [...parametros_calculados]
            setParametros(newParametrosCalculados)
            setFases(fases_calculadas)
            //console.log(parametros)
        }
        else{
            
            const subset1 = doc?.sistema_de_pesos_y_tiempos.slice(opcionesGrafico.dataZoom[0].startValue, opcionesGrafico.dataZoom[0].endValue)
            const { fases: fases_calculadas, parametros: parametros_calculados } = await runNordic(subset1)
            setParametros(parametros_calculados)
            setFases(fases_calculadas)
        }
        //console.log("Data",doc.sistema_de_pesos_y_tiempos)
        //console.log("Subset",subset1)
        //console.log("Fases",fases_calculadas)
        //console.log(doc)
        setreCalculando(false)
          
    }

    const guardarnewParametros = async () => {
        try {
            setCargando(true)
            const url = `prueba/${id}`
            const method = "patch"
            const data = {
                // ...extra,
                //atleta: atleta,
                //datos_brutos: datos_brutos.current,
                //video,
                //sistema_de_pesos_y_tiempos,
                fases: [ ...newfases ],
                parametros: [ ...newparametros ],
                inicioFases,
                finFases,
                //tipoPrueba: "CMJ",
            }
            await API({
                url,
                method,
                data
            })
            notification.success({ description: 'Prueba editada' })
            //navigate('/pruebas')
        } catch (err) {
            notification.error({
                description: err?.response?.data?.mensaje || err.toString()
            })
        } finally {
            setCargando(false)
        }

    }

   
    const descargarData = async () => {
        const { data } =  descargarCSV()

        const href = window.URL.createObjectURL(data);
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', `${doc?.nombre} ${doc?.tipoPrueba} ${dayjs(doc.createdAt).format("DD-MM-YYYY")}.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(href);
    }



      const descargarCSV = () => {
       
      
        // Preparar los datos para el CSV
        const datosCSV = [["Parametro", "valor"]];
        for (const elemento of doc?.parametros) {
          datosCSV.push([elemento.nombre, elemento.valor]);
        }
      
        // Convertir los datos a formato CSV
        const csv = datosCSV.map(fila => fila.join(",")).join("\n");
      
        // Crear un blob con los datos CSV
        const blob = new Blob([csv], { type: "text/csv" });
      
        // Generar un nombre de archivo con fecha y hora
        // const nombreArchivo = `datos_${new Date().toISOString().slice(0, 10)}.csv`;
      
        // Crear un enlace para descargar el archivo
        // const url = URL.createObjectURL(blob);
        // const enlace = document.createElement("a");
        // enlace.href = url;
        // enlace.download = nombreArchivo;
        // enlace.click();
      
        // // Eliminar el enlace una vez descargado el archivo
        // enlace.parentNode.removeChild(enlace);
        return { data: blob }
      }

    const cargarAtleta = async () => {
        try {
            setCargando(true)
            const { data } = await API(`atleta/${doc?.atleta}`)
            setAtleta(data.doc)
        } catch (error) {
            notification.error({ description: error.response.data.mensaje })
            navigate('/pruebas')
        } finally {
            setCargando(false)
        }
    }

    const miga = [
        {
            title: <Link to="/">Inicio</Link>
        },
        {
            title: <Link to="/pruebas">Muestra realizada</Link>
        },
        {
            title: 'Detalle'
        }
    ]

    

    let parametros = doc?.parametros || []
    let fases = doc?.fases || []

    if (muestra_seleccionada) {

        parametros = parametros.map((p, i) => {
            if (muestra_seleccionada?.parametros[i]) {
                p.media = muestra_seleccionada?.parametros[i].valor
                p.porcentaje = ((p.valor / p.media) - 1) * 100
                p.dif = p.valor - p.media

            }
            return p
        })
    }

    


    useEffect(() => {
        cargar()
    }, [])

    useEffect(() => {
        if (doc?.atleta) {
            cargarAtleta()
        }
    }, [doc])



    return <div >
        <div style={{ padding: 32 }}>
            <Breadcrumb items={miga} />
        </div>
        <br></br>
        <Header>
            <Flex gap="middle" justify="space-between" align="center" style={{ height: '100%' }}>
                <Flex gap="middle">
                    <Typography.Text>{doc?.nombre || 'Anonimo'}</Typography.Text>
                    
                    <Typography.Text>{doc?.momento || ''}</Typography.Text>
                </Flex>
                <Button type="primary" icon={<FilePdfFilled />} onClick={descargar}>Descargar Resumen</Button>
                <Button type="primary" icon={<DatabaseOutlined />} onClick={descargarData}>Descargar Data</Button>
                {/* https://ant.design/components/icon*/}
                {dayjs(doc?.createdAt).format("YYYY/MM/DD hh:mm a")}
            </Flex>
        </Header>
        <div style={{ padding: 32 }}>
            <Typography.Paragraph>{doc?.nota || 'No se ingreso nota profesional'}</Typography.Paragraph>
            <Typography.Title style={{ textAlign: 'center' }}>{doc?.tipoPrueba || ''} </Typography.Title>
           

            <Flex gap="middle" justify="space-between">
                {
                    doc?.fases?.map((f, i) => <Card key={i} style={{ flex: 1 }}>
                        <Typography.Title level={5}>{f.nombre}</Typography.Title>
                        <Typography.Text>{f?.tiempo?.duracion.toFixed(2)*1000} ms</Typography.Text>
                    </Card>)
                }
            </Flex>
            <br></br>
            <br></br>
            <Flex gap="middle" justify="space-around">
                {<Typography.Text>       </Typography.Text>}
                {<Typography.Text>       </Typography.Text>}
                {<SeleccionarMuestra onSeleccionar={setMuestraSeleccionada} tipe={doc.tipoPrueba} />}
               
                {<Typography.Text>       </Typography.Text>}
                {<Typography.Text>       </Typography.Text>}

            </Flex>
            <br></br>
           { <Table dataSource={Array.isArray(doc.parametros) && doc.parametros.length !== 0 && recalculando === false ? doc.parametros : newparametros} pagination={false} scroll={{ y: 1200 }}>
                <Table.Column title="Parametro" dataIndex={'nombre'} />
                {<Table.Column 
                    title="Valor" 
                    dataIndex={'valor'} 
                    render={(v, record) => (
                        <Typography.Title level={5} style={{ margin: 0, color: token.colorPrimary }}>
                        {v.toFixed(2)}{record.u ? ` ${record.u}` : ''}
                        </Typography.Title>
                    )} 
                    />
}
                {
                    muestra_seleccionada ? <>
                        <Table.Column title="Muestra" dataIndex={'media'} render={(v) => v && v?.toFixed(2)} />
                        <Table.Column title="% diferencia" dataIndex={'porcentaje'} render={(v) => v && v?.toFixed(2) + '%'} />
                        <Table.Column title="Dif total" dataIndex={'dif'} render={(v) => v && v?.toFixed(2)} />
                    </> : null
                }
            </Table>}
            <br></br>



        </div>
    </div>
}
export default MuestraDetalle