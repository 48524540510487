import "./estilo.css"
import { Button, Card, Col, Form, Input, Row, Typography } from "antd"
import LoginSevice from "../../Service/login"

const Login=()=>{
    const {acceder,cargando} = LoginSevice()

    return <div  className="pagina-login">
    <Row justify={'center'}>
        <Col xs={24} sm={8} md={6}>
            <Typography.Title>SEFUM</Typography.Title>
        <Card style={{maxWidth:400}}>
            <Form layout="vertical" autoComplete={'false'} disabled={cargando} onFinish={acceder}>
                <Form.Item label="Usuario" name="usuario">
                    <Input/>
                </Form.Item>
                <Form.Item label="Contraseña" name="clave">
                    <Input.Password/>
                </Form.Item>
                <Button block htmlType="submit" loading={cargando}>Acceder</Button>
            </Form>
        </Card>
        </Col>
    </Row>
   
    </div>
}

export default Login