import { Breadcrumb, Button, Dropdown, Flex, Table } from "antd"
import {  DeleteOutlined, DownOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { useEffect } from "react"
import { Link, useNavigate } from "react-router-dom"

import { useInstitucion } from "../../../Hooks/Institucion/useInstitucion";

const InstitucionListado=()=>{
    const navigate=useNavigate()
    const {instituciones,getAllInstitucion,eliminar} = useInstitucion()
    const miga=[
        {
            title:<Link to="/">Inicio</Link>
        },
        {
            title:'Instituciones'
        }
    ]
    const menu=[
        {
            label:'Editar',
            key:1,
            icon:<EditOutlined/>
        },
        {
            label:'Borrar',
            key:3,
            icon:<DeleteOutlined/>,
            danger:true
        }
    ]

    const Acciones=({ins})=>{
        const click=({key})=>{
            if(key=='1'){
                navigate(`/institucion/${ins._id}`)
                return
            }
            else if(key=='3'){
                {eliminar(ins._id)}
                return
            }
        }
        return <Dropdown.Button icon={<DownOutlined/>} menu={{items:menu,onClick:click}}>Acciones</Dropdown.Button>
    }
  
    useEffect(()=>{
        getAllInstitucion()
        
    },[])

    return <div style={{padding:32}}>
        <Flex gap="middle" align="center">
            <Breadcrumb items={miga} />
            <Link to='/institucion/guardar'><Button icon={<PlusOutlined/>} /></Link>
        </Flex>
        <br></br>
        <Table dataSource={instituciones} pagination={false}>
            <Table.Column title="Nombre" dataIndex={'nombre'}/>
            <Table.Column title="Acciones" render={(d)=>(<Acciones ins={d}/>)}/>
        </Table>
    </div>
}

export default InstitucionListado