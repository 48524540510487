import API from '../../API'
import { useState } from 'react'
import  useSesion  from '../Sesion'
export function useMuestra () {
    const [muestras, setMuestras] = useState([])
    const {usuario} = useSesion()
    
    const loadMuestras = async () => {
        try {
       
            const { data } = await API('muestra/all', { params: usuario?.rol === 'Admin' ? {} : { institucion: usuario?.institucion } })
            setMuestras(data.docs)
        } catch (err) {
            console.log("Error cargando las muestras")
        } 
    }

    const deleteMuestra=async (muestra)=>{
            await API.delete(`muestra/${muestra._id}`)      
            setMuestras(muestras.filter((m)=>m._id!==muestra._id))
    }

    return {
        muestras,
        loadMuestras,
        deleteMuestra

    }

}