import { useState } from "react"
import API from "../../API";
import { useNavigate } from "react-router-dom";
import useSesion from "../Sesion";
export function useInstitucion() {
    const navigate=useNavigate()
    const {usuario} = useSesion()
    const [instituciones, setInstituciones] = useState([])
    const [cargando,setCargando] = useState(false)

    const getAllInstitucion=async ()=>{
   
            setCargando(true)
            const {data} = await API('institucion')
            setInstituciones(data.docs)
            //console.log(data.docs)
   
    }

    const getInstitucion = async (id) => {
      
            if(usuario?.rol === 'Admin'){
                setInstituciones([{nombre:"Sefum Admin"}])
            }
            else{
                const { data } = await API(`institucion/${id}`)
                setInstituciones([data.doc])
                //console.log(data.doc)
            }
    }
    const eliminar=async (id)=>{
        try{
            setCargando(true)
            const url = `institucion/${id}`
            const method = "delete"
            //console.log(url)
            await API({
                url,
                method
            })
        }catch(err){
            console.log(err)
        }finally{
            setCargando(false)
            getAllInstitucion()
        }
    }
    const guardar=async (data)=>{
            try{
                setCargando(true)
                const url = 'institucion/'
                const method = "post"
                await API({
                    url,
                    method,
                    data
                })
               
                navigate('/instituciones')
            }catch(error){
    
            }finally{
                setCargando(false)
            }
        }
    
    const editInstitucion = async (id)=>{
        try{
            setCargando(true)
            const {data} = await API(`institucion/${id}`)
            setInstituciones([data.doc])
        }catch(err){
            //console.log(err)
        }finally{
            setCargando(false)
        }

    }
    
  

    return {
        instituciones,
        getInstitucion,
        guardar,
        editInstitucion,
        getAllInstitucion,
        cargando,
        eliminar
    }
}
