import { Breadcrumb } from "antd"
import {  Link } from "react-router-dom"

import { GraficaPrueba } from "../../../Componentes/Pruebas/GraficaPrueba";
import { PruebaContextProvider } from "../../../Contexts/Prueba/Prueba";
import { HeaderPruebaDetalle } from "../../../Componentes/Pruebas/HeaderPruebaDetalle";



const PruebaDetalle = () => {
    
    const miga = [
        {
            title: <Link to="/">Inicio</Link>
        },
        {
            title: <Link to="/pruebas">Pruebas realizadas</Link>
        },
        {
            title: 'Detalle'
        }
    ]

    return <PruebaContextProvider>
        <div style={{ padding: 32 }}>
            <Breadcrumb items={miga} />
        </div>
        <br></br>
        <HeaderPruebaDetalle/>
        <div style={{ padding: 32 }}>
            <GraficaPrueba />
        </div>
        
    </PruebaContextProvider>
    }
export default PruebaDetalle