import { useState } from "react"
import  API  from "../../API"
import useSesion from "../Sesion"
import dayjs from "dayjs";

export function usePrueba(){
    const [pruebas, setPruebas] = useState([])
    const {usuario} = useSesion()
    
    const getPrueba = async (id) => {
        const { data } = await API(`prueba/${id}`)
        setPruebas([data.doc])
        console.log(data.doc)
    }
    
    const getPruebas = async (payload) => {
        const params = {
                        fi: payload.fecha[0].toDate(),
                        ff: payload.fecha[1].toDate(),
                        mm: payload.momento,
                        tp: payload.tipoPrueba,
                        in: usuario?.rol === 'Admin' ? payload.institucion : usuario?.institucion,
                        at: payload.atleta
                        }
    
        const { data } = await API('prueba', { params })
        setPruebas(data.docs)
    }
    
    const deletePrueba=async (prueba)=>{
        await API.delete(`prueba/${prueba._id}`)      
        setPruebas(pruebas.filter((p)=>p._id!==prueba._id))
    }
    const cargarFecha = async (form, setFiltroPrueba) =>{
        const fechaU = [0,0]
        try {
            const params = {
                fi: dayjs("2020-01-01", "YYYY-MM-DD").toDate(),
                ff: dayjs().toDate(),
                in: usuario?.rol === 'Admin' ? '' : usuario?.institucion
            };
            
            const { data } = await API('prueba',{params})
            fechaU[1] = data.docs[0].updatedAt.split("T")[0] 
            for (let doc of data.docs) {
                if(doc.updatedAt.split("T")[0] !== fechaU[1]){
                    fechaU[0] = doc.updatedAt.split("T")[0]
                    break
                }   
            }
            setFiltroPrueba(prev => ({ ...prev, fecha: [dayjs(fechaU[0], "YYYY-MM-DD"), dayjs(fechaU[1], "YYYY-MM-DD")] }))
            form.setFieldsValue({
                    fecha: [dayjs(fechaU[0], "YYYY-MM-DD"), dayjs(fechaU[1], "YYYY-MM-DD").add(1, "day")]
            })
            form.submit()
        } catch (err) {
            console.log("Error cargando Fechas")
        } 
    }

    return{
        pruebas,
        getPruebas,
        deletePrueba,
        cargarFecha,
        getPrueba
    }
}