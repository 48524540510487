import { usePruebaContext } from "../../Hooks/Prueba/usepruebacontext"
import { theme, Typography, Table } from "antd";
const { useToken } = theme;

export function TableParamsPrueba({newparametros, muestra_seleccionada, recalculando}) {
    const { pruebas} = usePruebaContext()
    const { token } = useToken()

    let parametros = pruebas[0]?.parametros || []
    //let fases = pruebas[0]?.fases || []
    
    if (muestra_seleccionada) {
    
        parametros = parametros.map((p, i) => {
            if (muestra_seleccionada?.parametros[i]) {
                p.media = muestra_seleccionada?.parametros[i].valor
                p.porcentaje = ((p.valor / p.media) - 1) * 100
                p.dif = p.valor - p.media
    
            }
            return p
        })
        }


    return(
        <>
        
        { <Table rowKey={'id'} dataSource={Array.isArray(pruebas[0]?.parametros) && pruebas[0]?.parametros.length !== 0 && recalculando === false ? pruebas[0]?.parametros : newparametros} pagination={false} scroll={{ y: 1200 }}>
                
                <Table.Column title="Parametro" dataIndex={'nombre'} />
                {<Table.Column 
                    title="Valor" 
                    dataIndex={'valor'} 
                    render={(v, record) => (
                        <Typography.Title level={5} style={{ margin: 0, color: token.colorPrimary }}>
                        {v !==null ? v.toFixed(2) : ''}{record.u ? ` ${record.u}` : ''}
                        </Typography.Title>
                    )} 
                    />
                }
                {
                muestra_seleccionada ? <>
                    <Table.Column title="Muestra" dataIndex={'media'} render={(v) => v && v?.toFixed(2)} />
                    <Table.Column title="% diferencia" dataIndex={'porcentaje'} render={(v) => v && v?.toFixed(2) + '%'} />
                    <Table.Column title="Dif total" dataIndex={'dif'} render={(v) => v && v?.toFixed(2)} />
                    </> : null
                }
                
            </Table>}
        
        </>
    )

}